import { isGraphQLError } from '@/graphql'
import {
  describeImportSiteExecution,
  listImportSiteExecutions,
  Status,
} from '@/graphql/satellite-site'
import { CellClickHandler, Table } from '@/store/module/table'
import moment from 'moment'
import { Router, useRouter } from 'vue-router'

interface BatchResult {
  location: string
  total: number
  succeed: number
  errors: number
}

export class BatchTable extends Table {
  // バッチステータス
  protected status?: Status

  protected results: { [name: string]: BatchResult } = {}

  protected router?: Router

  // 作成、変更日時の日付形式
  private dateFormat = 'YYYY-MM-DD HH:mm:ss'

  constructor() {
    super()

    this.columns = [
      { text: 'ID' },
      { text: '結果', width: '30%' },
      { text: 'ステータス', width: '10%', align: 'center' },
      { text: '開始時間', width: '10%', align: 'center' },
      { text: '終了時間', width: '10%', align: 'center' },
    ]
  }

  setup(targetParentElementId?: string) {
    this.router = useRouter()
    return super.setup(targetParentElementId)
  }

  // バッチ名がクリックされたときのハンドラ
  private onClickBatchName: CellClickHandler = (cell) => {
    const name = cell.text
    if (name && this.router) {
      this.router.replace(`/satellite-site/batch/import/site/${name}`)
    }
  }

  private getStatus(status: string) {
    switch (status) {
      case 'RUNNING':
        return '実行中'
      case 'SUCCEEDED':
        return '成功'
      case 'FAILED':
        return '失敗'
      case 'TIMED_OUT':
        return 'タイムアウト'
      case 'ABORTED':
        return '中断'
      default:
        return status
    }
  }

  protected async request() {
    if (this.pageRequesting) {
      return
    }

    const batchNames: string[] = []

    this.pageRequesting = true
    try {
      const result = await listImportSiteExecutions(this.status, this.nextToken)
      const resultExecutions =
        result.data?.satellitesiteListImportSiteExecutions
      // console.log(resultExecutions)

      this.nextToken = resultExecutions?.nextToken
        ? (resultExecutions.nextToken as string)
        : undefined

      resultExecutions?.executions?.forEach((exe) => {
        batchNames.push(exe.name)
        this.table?.data.push([
          {
            text: exe.name,
            style: 'color: rgb(var(--v-theme-primary)); cursor: pointer;',
            click: this.onClickBatchName,
          },
          {
            id: `batch-${exe.name}`,
            text: this.results[exe.name]
              ? `${this.results[exe.name].succeed} / ${
                  this.results[exe.name].total
                }`
              : '',
          },
          {
            text: this.getStatus(exe.status),
            align: 'center',
          },
          {
            text: moment(exe.startDate).format(this.dateFormat),
            align: 'center',
          },
          {
            text: exe.stopDate
              ? moment(exe.stopDate).format(this.dateFormat)
              : '-',
            align: 'center',
          },
        ])
      })
    } catch (err) {
      if (isGraphQLError(err)) {
        if (this.table?.data) {
          this.table.errors = []
        }

        err.errors?.forEach((error) => {
          if (error.errorType == 'Unauthorized') {
            this.table?.errors.push('表示する権限がありません')
          } else {
            console.log(error)
          }
        })
      } else {
        console.log(err)
      }
    } finally {
      this.pageRequesting = false
    }

    for (const name of batchNames) {
      const result = await describeImportSiteExecution(name)
      const describe = result.data?.satellitesiteDescribeImportSiteExecution
      if (!describe || !describe.domains || !describe.location) continue

      const domains = describe.domains.map((d) => {
        const errorType = d.results?.find((ret) => ret.error !== null)
        const noError = errorType === undefined
        const completed =
          d.results?.find((ret) => ret.type === 'cloudfront') !== undefined

        let error = errorType?.error
        switch (error) {
          case 'No such zone for cloudflare':
            error = 'DNSが登録されていません。'
            break
          case 'Rate exceeded':
            error = '一時的に実行可能処理数を超えました。'
            break
        }

        return {
          domain: d.domain,
          progress: !completed,
          succeed: noError && completed,
          error,
        }
      })

      const succeed = domains.reduce((p, d) => (p += d.succeed ? 1 : 0), 0)
      const errors = domains.reduce((p, d) => (p += d.error ? 1 : 0), 0)

      const batchResult: BatchResult = {
        location: describe.location,
        total: describe.domains.length,
        succeed,
        errors,
      }
      this.results[name] = batchResult

      const target = document.getElementById(`batch-${name}`)
      if (!target) continue
      target.textContent = `サイト数 ${batchResult.total} / 成功 ${batchResult.succeed} / エラー ${batchResult.errors}`
    }
  }
}
