import { GraphQLMutationResult, GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const deleteSiteValidation = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($location: String!) {
          satellitesiteDeleteSiteValidation(location: $location) {
            sites {
              domain
              errors
            }
          }
        }
      `,
      { location },
    ),
  ) as GraphQLQueryResult<'satellitesiteDeleteSiteValidation'>

export const deleteSiteExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteDeleteSiteExecution(location: $location) {
            id
          }
        }
      `,
      { location },
    ),
  ) as GraphQLMutationResult<'satellitesiteDeleteSiteExecution'>

export const listDeleteSiteExecutions = (status?: Status, nextToken?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListDeleteSiteExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      { status, nextToken },
    ),
  ) as GraphQLQueryResult<'satellitesiteListDeleteSiteExecutions'>

export const describeDeleteSiteExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeDeleteSiteExecution(name: $name) {
            domain
            results {
              error
              time
              type
            }
          }
        }
      `,
      {
        name,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeDeleteSiteExecution'>
