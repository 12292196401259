<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="12" class="text-subtitle-1 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">サイト削除の実行履歴</span>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="flex-row pt-0" fluid>
      <v-card tile>
        <v-card-item id="satellite-site-batch-list" :class="$style.batchTable">
          <Table :table-key="batchTableKey" :errors="store.state.errors" />
        </v-card-item>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import Table from '@/components/parts/Table.vue'
import store from '@/store/satellite-site/batch/delete/site/list'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SatelliteSiteBatchDeleteSiteList',
  components: {
    Table,
  },
  setup() {
    const batchTableKey = store.setup()

    return {
      store,
      batchTableKey,
    }
  },
})
</script>


<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.batchTable {
  overflow: scroll;
  height: calc(100vh - 48px - 32px - 53px - 16px);
}
.detail {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
}
</style>
