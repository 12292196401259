import { GraphQLMutationResult, GraphQLQueryResult, Types } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const loadSiteTemplate = (domain: string, noCache?: boolean) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($domain: String!, $noCache: Boolean) {
          satellitesiteLoadSiteTemplate(domain: $domain, noCache: $noCache) {
            file
            content
            location
          }
        }
      `,
      { domain, noCache },
    ),
  ) as GraphQLQueryResult<'satellitesiteLoadSiteTemplate'>

export const uploadSiteTemplateFile = (domain: string, location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($domain: String!, $location: String!) {
          satellitesiteUploadSiteTemplateFile(
            domain: $domain
            location: $location
          ) {
            location
            error
          }
        }
      `,
      { domain, location },
    ),
  ) as GraphQLQueryResult<'satellitesiteUploadSiteTemplateFile'>

export const updateSiteTemplateExecution = (domain: string, location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($domain: String!, $location: String!) {
          satellitesiteUpdateSiteTemplateExecution(
            domain: $domain
            location: $location
          ) {
            id
          }
        }
      `,
      { domain, location },
    ),
  ) as GraphQLMutationResult<'satellitesiteUpdateSiteTemplateExecution'>

export const updateSiteFilesExecution = (
  domain: string,
  files: Types.SatelliteSiteUpdateSiteFile[],
) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($domain: String!, $files: [SatelliteSiteUpdateSiteFile!]!) {
          satellitesiteUpdateSiteFilesExecution(
            domain: $domain
            files: $files
          ) {
            id
          }
        }
      `,
      { domain, files },
    ),
  ) as GraphQLMutationResult<'satellitesiteUpdateSiteFilesExecution'>

export const describeUpdateSiteExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeUpdateSiteExecution(name: $name) {
            status
            startDate
            stopDate
            output
          }
        }
      `,
      { name },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeUpdateSiteExecution'>

export const updateContentValidation = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($location: String!) {
          satellitesiteUpdateContentValidation(location: $location) {
            domain
            contents {
              type
              content
              error
            }
          }
        }
      `,
      { location },
    ),
  ) as GraphQLQueryResult<'satellitesiteUpdateContentValidation'>

export const updateContentExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteUpdateContentExecution(location: $location) {
            id
          }
        }
      `,
      { location },
    ),
  ) as GraphQLMutationResult<'satellitesiteUpdateContentExecution'>

export const listUpdateContentExecutions = (
  status?: Status,
  nextToken?: string,
) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListUpdateContentExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      { status, nextToken },
    ),
  ) as GraphQLQueryResult<'satellitesiteListUpdateContentExecutions'>

export const describeUpdateContentExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeUpdateContentExecution(name: $name) {
            status
            startDate
            stopDate
            output
          }
        }
      `,
      { name },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeUpdateContentExecution'>
