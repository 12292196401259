<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="8" class="text-subtitle-1 pr-0 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">
            <router-link to="/satellite-site/batch/import/site"
              >サイトインポートバッチ</router-link
            >
          </span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">詳細 {{ store.state.id }}</span>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn to="/satellite-site/import/upload2" color="primary" tile>
            サイト生成リストのアップロード
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-circular
      v-if="store.state.show.loading && !store.state.show.result"
      indeterminate
      class="mx-3"
    />

    <v-container v-if="store.state.show.result" class="flex-row pt-0" fluid>
      <v-card class="pb-2" tile>
        <v-card-item>
          <v-row no-gutters>
            <v-col>
              <div class="pa-4">
                <b>バッチ処理内容</b>
                <span
                  @click="store.clickFilterAll"
                  style="cursor: pointer"
                  class="text-subtitle-2 rounded pa-1 px-2 mx-2"
                  :class="store.state.filter == 'all' ? 'bg-info' : 'text-info'"
                  >ドメイン数 {{ store.state.result.total }}</span
                >
                <span
                  @click="store.clickFilterCompleted"
                  style="cursor: pointer"
                  class="text-subtitle-2 rounded pa-1 mr-2 px-2"
                  :class="
                    store.state.filter == 'completed'
                      ? 'bg-success'
                      : 'text-success'
                  "
                  >完了 {{ store.state.result.succeed }}</span
                >
                <span
                  @click="store.clickFilterError"
                  style="cursor: pointer"
                  class="text-subtitle-2 rounded pa-1 px-2"
                  :class="
                    store.state.filter == 'error' ? 'bg-error' : 'text-error'
                  "
                  >エラー {{ store.state.result.errors }}</span
                >
              </div>
            </v-col>

            <v-col cols="1" class="text-right pa-2">
              <v-progress-circular
                v-if="store.state.show.loading"
                indeterminate
                size="24"
                class="ma-2 mx-4"
              />
              <v-btn
                v-else
                @click="store.reload"
                size="24"
                elevation="0"
                :rounded="false"
                :border="false"
                flat
                tile
                ><v-icon icon="mdi-reload" class="ma-2 mx-4"
              /></v-btn>
            </v-col>
          </v-row>
        </v-card-item>

        <v-card-item>
          <v-divider class="my-2 mt-0" />
          <div v-for="domain in store.state.result.domains" :key="domain">
            <v-row
              v-if="
                store.state.filter == 'all' ||
                (store.state.filter == 'completed' && domain.succeed) ||
                (store.state.filter == 'error' && !domain.succeed)
              "
            >
              <v-col cols="4">
                <div class="px-2">
                  <v-progress-circular
                    v-if="domain.progress"
                    indeterminate
                    class="ml-1"
                    size="20"
                  />
                  <v-icon
                    v-else
                    :icon="domain.succeed ? 'mdi-check' : 'mdi-close'"
                    :color="domain.succeed ? 'success' : 'error'"
                  />
                  {{ domain.domain }}
                </div>
              </v-col>
              <v-col>
                <div class="px-2 text-error">
                  {{ domain.error }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-item>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

import detailStore from '@/store/satellite-site/batch/import/site/detail'

// import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteBatchDetail',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    const store = inject(detailStore.key, detailStore)
    if (!store) {
      throw new Error('Empty store')
    }

    store.setup()

    return { store }
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.chevron {
  opacity: var(--v-disabled-opacity);
}
.usersTable {
  overflow: scroll;
  // header - footer - actions
  height: calc(100vh - 48px - 32px - 60px);
  padding: 0;
}
</style>
