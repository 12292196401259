import { GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'

export const searchDomain = (query: string, match?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($query: String!, $match: String) {
          satellitesiteSearchDomain(query: $query, match: $match)
        }
      `,
      { query, match },
    ),
  ) as GraphQLQueryResult<'satellitesiteSearchDomain'>
