<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="12" class="text-subtitle-1 pr-0 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">
            <router-link to="/satellite-site/domain/register"
              >新しいドメインの登録</router-link
            >
          </span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">
            <router-link to="/satellite-site/batch/register/domain"
              >新しいドメイン登録の実行履歴</router-link
            >
          </span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">詳細 {{ store.state.id }}</span>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-circular
      v-if="store.state.show.loading"
      indeterminate
      class="mx-3"
    />

    <v-container v-if="store.state.show.result" class="flex-row pt-0" fluid>
      <v-card tile>
        <v-card-item class="pa-2">
          <v-row>
            <v-col class="pl-4 align-self-center">
              <b>バッチ処理内容</b>
              <span class="text-subtitle-2 text-info pa-1 px-2 mx-2"
                >ドメイン数 {{ store.state.result.total }}</span
              >
              <span class="text-subtitle-2 text-success pa-1 px-2"
                >完了 {{ store.state.result.succeed }}</span
              >
              <span class="text-subtitle-2 text-warning pa-1 px-2 mx-2"
                >処理中 {{ store.state.result.progress }}</span
              >
              <span class="text-subtitle-2 text-error pa-1 px-2"
                >エラー {{ store.state.result.errors }}</span
              >
            </v-col>
            <v-col class="text-right align-self-center">
              <v-btn
                @click="store.reload"
                flat
                tile
                elevation="0"
                :disabled="store.state.disabled.reload"
              >
                <v-progress-circular
                  v-if="store.state.disabled.reload"
                  size="20"
                  indeterminate
                />
                <v-icon v-else icon="mdi-reload" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-item v-if="store.state.result.domains.length" class="pb-4">
          <v-divider class="mb-4" />
          <v-row v-for="domain in store.state.result.domains" :key="domain">
            <v-col>
              <div class="px-3">
                <v-progress-circular
                  v-if="domain.progress"
                  size="22"
                  indeterminate
                />
                <v-icon
                  v-else
                  :icon="domain.succeed ? 'mdi-check-bold' : 'mdi-close-thick'"
                  :color="domain.succeed ? 'success' : 'error'"
                />
                {{ domain.domain }}
              </div>
            </v-col>
            <v-col
              v-if="domain.message"
              cols="9"
              class="text-left text-warning"
            >
              {{ domain.message }}
            </v-col>
            <v-col v-else cols="9" class="text-left text-error">
              {{ domain.error }}
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

import detailStore from '@/store/satellite-site/batch/register/domain/detail'

export default defineComponent({
  name: 'SatelliteSiteBatchRegisterDomainDetail',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    const store = inject(detailStore.key, detailStore)
    if (!store) {
      throw new Error('Empty store')
    }

    store.setup()

    return { store }
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.chevron {
  opacity: var(--v-disabled-opacity);
}
.usersTable {
  overflow: scroll;
  // header - footer - actions
  height: calc(100vh - 48px - 32px - 60px);
  padding: 0;
}
</style>
