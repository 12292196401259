import { GraphQLMutationResult, GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const registerDomainValidation = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($location: String!) {
          satellitesiteRegisterDomainValidation(location: $location) {
            sites {
              domain
              errors
            }
          }
        }
      `,
      {
        location,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteRegisterDomainValidation'>

export const registerDomainExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteRegisterDomainExecution(location: $location) {
            id
          }
        }
      `,
      {
        location,
      },
    ),
  ) as GraphQLMutationResult<'satellitesiteRegisterDomainExecution'>

export const listRegisterDomainExecutions = (
  status?: Status,
  nextToken?: string,
) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListRegisterDomainExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      {
        status,
        nextToken,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteListRegisterDomainExecutions'>

export const describeRegisterDomainExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeRegisterDomainExecution(name: $name) {
            domain
            results {
              error
              nameServers
              time
              type
            }
          }
        }
      `,
      {
        name,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeRegisterDomainExecution'>
