
import Table from '@/components/parts/Table.vue'
import store from '@/store/satellite-site/batch/delete/site/list'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SatelliteSiteBatchDeleteSiteList',
  components: {
    Table,
  },
  setup() {
    const batchTableKey = store.setup()

    return {
      store,
      batchTableKey,
    }
  },
})
