import {
  describeImportDomainExecution,
  listPendingDomainExecutions,
} from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import ClipboardJS from 'clipboard'
import { InjectionKey, onMounted, reactive } from 'vue'

interface Show {
  empty: boolean
  result: boolean
  loading: boolean
  request: boolean
  taskExpand: boolean
}

interface Disabled {
  reload: boolean
}

interface Result {
  nameServers: string[]
  domains: string[]
}

interface ExtensionInDomain {
  domain: string
  onamaeId: string
  succeed?: boolean
  error: string
}

interface ExtensionDomain {
  domain: string
  onamaeId: string
  progress: boolean
  succeed: boolean
  error: string
}

interface Extension {
  signal: boolean
  loading: boolean
  domains: ExtensionDomain[]
}

interface TaskDomain {
  domain: string
  succeed: boolean
}

interface Task {
  name: string
  startDate: Date
  status: string
  succeed: number
  progress: number
  domains?: TaskDomain[] | null
  location?: string | null
  errors?: string[] | null
}

interface State {
  show: Show
  disabled: Disabled
  tasks: Task[]
  results: Result[]
  json: string
  extension: Extension
}

const state = reactive<State>({
  show: {
    empty: false,
    result: false,
    loading: true,
    request: false,
    taskExpand: false,
  },
  disabled: {
    reload: false,
  },
  results: [],
  tasks: [],
  json: '',
  extension: {
    signal: false,
    loading: false,
    domains: [],
  },
})

const requestListPendingZone = async () => {
  state.show.result = false
  state.show.empty = false
  state.show.request = false
  state.show.loading = true
  state.disabled.reload = true
  // const result = await listPendingZones()
  const result = await listPendingDomainExecutions('RUNNING')
  const zones = result.data?.satellitesiteListPendingDomain
  if (zones && zones.length) {
    state.results = zones
    state.show.result = true
    state.json = JSON.stringify(zones)
  } else {
    state.show.empty = true
    state.json = ''
  }

  const listDomainExecutions =
    result.data?.satellitesiteListImportDomainExecutions
  const executions = listDomainExecutions?.executions
  state.show.loading = false

  if (executions) {
    state.tasks = []
    for (const task of executions) {
      const ret = await describeImportDomainExecution(task.name)
      const taskResult = ret.data?.satellitesiteDescribeImportDomainExecution
      if (taskResult && taskResult.domains) {
        const succeed = taskResult.domains.reduce(
          (p, c) => p + (c.succeed ? 1 : 0),
          0,
        )
        const progress = Math.floor((succeed / taskResult.domains.length) * 100)
        state.tasks.push({
          ...taskResult,
          progress,
          succeed,
          name: task.name,
          status: task.status,
          startDate: new Date(task.startDate),
        })
      }
    }
  }

  state.disabled.reload = false
}

const extensionSignalOn = () => {
  state.extension.signal = true
}

const extensionSignalOff = () => {
  state.extension.signal = false
  state.show.request = false
}

const extensionShowRequest = () => {
  state.extension.loading = true
  state.extension.domains = []
  state.results.forEach((result) => {
    result.domains.forEach((domain) => {
      state.extension.domains.push({
        domain,
        onamaeId: '',
        progress: true,
        succeed: false,
        error: '',
      })
    })
  })

  state.show.request = true
}

const extensionUpdateRequestResult = () => {
  const update = document.getElementById('extension-request-result')
  if (!update) return
  const input = update as HTMLInputElement
  const result = JSON.parse(input.value) as ExtensionInDomain
  const target = state.extension.domains.find((d) => d.domain === result.domain)
  if (!target) return
  target.onamaeId = result.onamaeId
  if (result.succeed === undefined) {
    target.progress = true
    target.succeed = false
  } else {
    target.progress = false
    target.succeed = result.succeed
  }

  if ('Not found' === result.error) {
    target.error = `お名前ID（${result.onamaeId}）のアカウントでは、登録ドメインが見つかりませんでした。`
  } else {
    target.error = result.error
  }
}

const extensionRequestComplated = () => {
  state.extension.loading = false
}

const taskExpandToggle = () => {
  state.show.taskExpand = !state.show.taskExpand
}

const reload = async () => {
  await requestListPendingZone()
}

const setup = () => {
  onMounted(async () => {
    await requestListPendingZone()

    new ClipboardJS('.copy-nameserver', {
      target: (elm) => {
        const e = elm as HTMLDivElement
        const target = e.querySelector('span')
        if (!target) throw new Error('No such target')

        e.classList.add('text-primary')
        setTimeout(() => {
          e.classList.remove('text-primary')
        }, 300)

        return target
      },
    })

    new ClipboardJS('.copy-domains', {
      target: (elm) => {
        const e = elm as HTMLDivElement
        const target = e.querySelector('textarea')
        if (!target) throw new Error('No such target')

        e.classList.add('text-primary')
        setTimeout(() => {
          e.classList.remove('text-primary')
        }, 300)

        return target
      },
    })
  })

  return
}

interface Store {
  state: State
  setup: () => void
  reload: () => void
  taskExpandToggle: () => void
  extensionSignalOn: () => void
  extensionSignalOff: () => void
  extensionShowRequest: () => void
  extensionUpdateRequestResult: () => void
  extensionRequestComplated: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  reload,
  taskExpandToggle,
  extensionSignalOn,
  extensionSignalOff,
  extensionShowRequest,
  extensionUpdateRequestResult,
  extensionRequestComplated,
}

export default store
