
import { defineComponent, inject } from 'vue'
import detailStore from '@/store/satellite-site/batch/update/site/detail'

export default defineComponent({
  name: 'SatelliteSiteBatchUpdateSiteDetail',
  setup() {
    const store = inject(detailStore.key, detailStore)
    if (!store) {
      throw new Error('Empty store')
    }

    store.setup()

    return { store }
  },
})
