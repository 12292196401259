
import { defineComponent, inject } from 'vue'
import updateContentsStore from '@/store/satellite-site/update-contents'
import FileDrop from '@/components/parts/FileDrop.vue'
import Table from '@/components/parts/Table.vue'

export default defineComponent({
  name: 'SatelliteSiteUpdateContents',
  components: {
    FileDrop,
    Table,
  },
  setup() {
    const store = inject(updateContentsStore.key, updateContentsStore)
    if (!store) throw new Error('Empty store')

    const resultTableKey = store.setup()

    return {
      store,
      resultTableKey,
    }
  },
})
