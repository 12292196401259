
import { defineComponent } from 'vue'

// import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteImportConsole',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    //
  },
})
