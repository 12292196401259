
import { defineComponent } from 'vue'
import store from '@/store/satellite-site/pending-zones'

export default defineComponent({
  name: 'SatelliteSiteIndex',
  setup() {
    store.setup()

    return {
      store,
    }
  },
})
