import { GraphQLMutationResult, GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const importDomainValidation = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($location: String!) {
          satellitesiteImportDomainValidation(location: $location) {
            sites {
              domain
              errors
              legacy {
                server
              }
            }
          }
        }
      `,
      {
        location,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteImportDomainValidation'>

export const importDomainExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteImportDomainExecution(location: $location) {
            id
          }
        }
      `,
      {
        location,
      },
    ),
  ) as GraphQLMutationResult<'satellitesiteImportDomainExecution'>

export const importSiteExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteImportSiteExecution(location: $location) {
            id
          }
        }
      `,
      {
        location,
      },
    ),
  ) as GraphQLMutationResult<'satellitesiteImportSiteExecution'>

export const listImportDomainExecutions = (
  status?: Status,
  nextToken?: string,
) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListImportDomainExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      {
        status,
        nextToken,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteListImportDomainExecutions'>

export const listImportSiteExecutions = (status?: Status, nextToken?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListImportSiteExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      {
        status,
        nextToken,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteListImportSiteExecutions'>

export const describeImportDomainExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeImportDomainExecution(name: $name) {
            domains {
              succeed
              domain
            }
            location
            errors
          }
        }
      `,
      {
        name,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeImportDomainExecution'>

export const describeImportSiteExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeImportSiteExecution(name: $name) {
            domains {
              domain
              results {
                error
                time
                type
              }
            }
            location
          }
        }
      `,
      {
        name,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeImportSiteExecution'>
