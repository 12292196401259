import { isGraphQLError } from '@/graphql'
import {
  getUploadLocation,
  deleteSiteValidation,
  deleteSiteExecution,
} from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { bindKeyPair, TableStructure, KeyPair } from '@/store/module/table'
import axios from 'axios'
import prettyBytes from 'pretty-bytes'
import { InjectionKey, onMounted, reactive } from 'vue'
import { Router, useRouter } from 'vue-router'
import { getFilesFromEntries } from '@/store/module/filedrop'

interface Show {
  info: boolean
  result: boolean
}

interface Disabled {
  info: boolean
  result: boolean
  execution: boolean
}

interface FileDrop {
  errors: string[]
  loading: boolean
  progress: number
  valid: boolean
  file?: File
  filesize?: string
}

interface Result {
  total: number
  errors: string[]
  loading: boolean
  location?: string
}

interface State {
  show: Show
  disabled: Disabled
  result: Result
  filedrop: FileDrop
}

const state = reactive<State>({
  show: {
    info: true,
    result: false,
  },
  disabled: {
    info: true,
    result: false,
    execution: false,
  },
  result: {
    total: 0,
    loading: false,
    errors: [],
  },
  filedrop: {
    progress: 0,
    loading: false,
    valid: false,
    errors: [],
  },
})

let resultTable: TableStructure = {
  columns: [
    { text: 'ドメイン', width: '30%' },
    { text: '', width: '70%' },
  ],
  data: [],
  errors: [],
}

const resetState = () => {
  state.filedrop.errors = []
  state.filedrop.progress = 0
  state.filedrop.loading = false
  state.filedrop.valid = false
  state.filedrop.file = undefined
  state.filedrop.filesize = undefined
  state.result.errors = []
  state.show.info = true
  state.show.result = false
  state.disabled.execution = false
}

let router: Router
const setup = () => {
  router = useRouter()

  const { keys, table } = bindKeyPair(resultTable.columns)

  onMounted(async () => {
    resetState()
    resultTable = table
  })

  return keys
}

const requestValidation = async (location: string) => {
  state.result.location = undefined
  resultTable.errors.splice(0)

  const result = await deleteSiteValidation(location)
  // Api Error
  if (!result.data) {
    console.log(result.errors)
    state.result.errors.push('サイトリストの検証に失敗しました。')
    result.errors?.forEach((error) => {
      resultTable.errors.push(error['message'])
    })

    return
  }

  let errorCount = 0
  resultTable.data.splice(0) // clear result table data
  const sites = result.data.satellitesiteDeleteSiteValidation.sites
  sites.forEach((site) => {
    const errors: string[] = []
    let hasError = false
    if (site.errors && site.errors.length > 0) {
      hasError = true
      errorCount++
      site.errors.map((error) => {
        switch (error) {
          case 'Duplicate domain':
            errors.push('ドメインが重複して指定されています。')
            break
          default:
            errors.push(error)
            break
        }
      })
    }

    const style = hasError
      ? 'color: rgb(var(--v-theme-error)); background: rgba(var(--v-theme-error), var(--v-pressed-opacity)); font-weight: bold'
      : undefined

    resultTable.data.push([
      { text: site.domain, style },
      { text: errors.join('\n'), style },
    ])
  })

  state.show.info = false
  state.show.result = true
  state.filedrop.valid = errorCount === 0

  if (errorCount) {
    state.result.errors.push(`サイトリストにエラーが${errorCount}件あります。`)
  } else {
    state.result.total = sites.length
    state.result.location = location
  }
}

const requestFileStatus = async () => {
  state.filedrop.loading = true

  const location = await getUploadLocation()
  const url = location.data?.satellitesiteGetUploadLocation.url
  if (!url) {
    state.filedrop.loading = false
    throw new Error('Failed getUploadLocation')
  }

  const file = state.filedrop.file
  if (!file) {
    state.filedrop.loading = false
    return
  }

  state.result.errors = []

  // ファイルのアップロード
  try {
    await axios.put(url, file, {
      onUploadProgress: (e: ProgressEvent) => {
        state.filedrop.progress = (e.loaded / e.total) * 100
      },
    })
    const location = new URL(url).pathname.split('/').pop()
    if (!location) throw new Error('Failed url parse')

    await requestValidation(location)
  } catch (err) {
    state.filedrop.errors.push('アップロードに失敗しました。')
    state.filedrop.valid = false
    console.log(err)
  } finally {
    state.filedrop.loading = false
  }
}

const setFiles = (files: File[]) => {
  state.filedrop.errors = []
  state.filedrop.file = undefined
  state.filedrop.filesize = undefined
  state.filedrop.progress = 0
  state.filedrop.loading = false
  state.filedrop.valid = false
  state.show.info = true
  state.show.result = false

  if (files.length > 1) {
    state.filedrop.errors.push('ファイルはひとつだけ選択してください。')
    return
  }

  const file = files[0]

  if (!/\.(csv|xls|xlsx)$/.test(file.name)) {
    state.filedrop.errors.push('対応していないファイル形式です。')
  }

  state.filedrop.file = file
  state.filedrop.filesize = prettyBytes(file.size)
  requestFileStatus()
}

const filedrop: FileSystemEntriesCallback = async (entries) => {
  const files = await getFilesFromEntries(entries)
  setFiles(files)
}

const onChangeFile = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (target.files) setFiles(Array.from(target.files))
}

const clickExecution = async () => {
  // バリデーション結果にエラーがある場合は無効
  if (state.result.errors.length) return
  // サイトリストのファイルがない場合は無効
  if (!state.result.location) return

  state.result.loading = true
  state.disabled.execution = true

  try {
    const result = await deleteSiteExecution(state.result.location)
    // Step Functions State Machine Execution Arn
    const id = result.data?.satellitesiteDeleteSiteExecution.id
    if (id) {
      // バッチ詳細画面に遷移する
      router.push(`/satellite-site/batch/delete/site/${id}`)
    }
  } catch (e) {
    console.log(e)
    state.result.errors.push(`サイト削除バッチの実行に失敗しました。`)
    if (isGraphQLError(e)) {
      e.errors?.forEach((err) => {
        state.result.errors.push(err.message)
      })
    }
  } finally {
    state.result.loading = false
  }
}

interface Store {
  state: State
  setup: () => KeyPair
  filedrop: FileSystemEntriesCallback
  onChangeFile: (e: Event) => void
  clickExecution: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  filedrop,
  onChangeFile,
  clickExecution,
}

export default store
