<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="12" class="text-subtitle-1 pr-0 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">インポートバッチ</span>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="flex-row" fluid>
      <v-btn to="/satellite-site/batch/import/domain" tile
        >ドメインインポートバッチの実行履歴</v-btn
      >
      <v-btn to="/satellite-site/batch/import/site" class="mx-2" tile
        >サイトインポートバッチの実行履歴</v-btn
      >
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteImportConsole',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    //
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.usersTable {
  overflow: scroll;
  // header - footer - actions
  height: calc(100vh - 48px - 32px - 60px);
  padding: 0;
}
</style>
