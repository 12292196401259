import { isGraphQLError } from '@/graphql'
import {
  describeDeleteSiteExecution,
  listDeleteSiteExecutions,
  Status,
} from '@/graphql/satellite-site'
import { CellClickHandler, Table } from '@/store/module/table'
import moment from 'moment'
import { Router, useRouter } from 'vue-router'

interface BatchResult {
  total: number
  succeed: number
  progress: number
  errors: number
  completed: boolean
}

export class BatchTable extends Table {
  // バッチステータス
  protected status?: Status

  protected results: { [name: string]: BatchResult } = {}

  protected router?: Router

  // 作成、変更日時の日付形式
  private dateFormat = 'YYYY-MM-DD HH:mm:ss'

  constructor() {
    super()

    this.columns = [
      { text: 'ID' },
      { text: '結果', width: '30%' },
      { text: 'ステータス', width: '10%', align: 'center' },
      { text: '開始時間', width: '10%', align: 'center' },
      { text: '終了時間', width: '10%', align: 'center' },
    ]
  }

  setup(targetParentElementId?: string) {
    this.router = useRouter()
    return super.setup(targetParentElementId)
  }

  // バッチ名がクリックされたときのハンドラ
  private onClickBatchName: CellClickHandler = (cell) => {
    const name = cell.text
    if (name && this.router) {
      this.router.replace(`/satellite-site/batch/update/site/${name}`)
    }
  }

  private getStatus(status: string) {
    switch (status) {
      case 'RUNNING':
        return '実行中'
      case 'SUCCEEDED':
        return '成功'
      case 'FAILED':
        return '失敗'
      case 'TIMED_OUT':
        return 'タイムアウト'
      case 'ABORTED':
        return '中断'
      default:
        return status
    }
  }

  protected async request() {
    if (this.pageRequesting) {
      return
    }

    const batchNames: string[] = []

    this.pageRequesting = true
    try {
      const result = await listDeleteSiteExecutions(this.status, this.nextToken)
      const resultExecutions =
        result.data?.satellitesiteListDeleteSiteExecutions
      // console.log(resultExecutions)

      this.nextToken = resultExecutions?.nextToken
        ? (resultExecutions.nextToken as string)
        : undefined

      resultExecutions?.executions?.forEach((exe) => {
        batchNames.push(exe.name)
        this.table?.data.push([
          {
            text: exe.name,
            style: 'color: rgb(var(--v-theme-primary)); cursor: pointer;',
            click: this.onClickBatchName,
          },
          {
            id: `batch-${exe.name}`,
            text: this.results[exe.name]
              ? `${this.results[exe.name].succeed} / ${
                  this.results[exe.name].total
                }`
              : '',
          },
          {
            text: this.getStatus(exe.status),
            align: 'center',
          },
          {
            text: moment(exe.startDate).format(this.dateFormat),
            align: 'center',
          },
          {
            text: exe.stopDate
              ? moment(exe.stopDate).format(this.dateFormat)
              : '-',
            align: 'center',
          },
        ])
      })
    } catch (err) {
      if (isGraphQLError(err)) {
        if (this.table?.data) {
          this.table.errors = []
        }

        err.errors?.forEach((error) => {
          if (error.errorType == 'Unauthorized') {
            this.table?.errors.push('表示する権限がありません')
          } else {
            console.log(error)
          }
        })
      } else {
        console.log(err)
      }
    } finally {
      this.pageRequesting = false
    }

    for (const name of batchNames) {
      describeDeleteSiteExecution(name).then((result) => {
        const describe = result.data?.satellitesiteDescribeDeleteSiteExecution
        if (!describe) return

        const domains: {
          domain: string
          progress: boolean
          succeed: boolean
          error?: string | null
        }[] = []

        for (const result of describe) {
          const succeed =
            result.results.find((r) => r.error !== null) === undefined
          const progress =
            result.results.find((r) => r.type === 'cloudfront') === undefined
          const error = result.results.find((r) => r.error !== null)?.error
          domains.push({
            domain: result.domain,
            error,
            progress,
            succeed,
          })
        }

        const total = describe.length
        const succeed = domains.filter((d) => d.succeed).length
        const progress = domains.filter((d) => d.progress).length
        const errors = domains.filter((d) => d.error).length

        const batchResult: BatchResult = {
          total,
          succeed,
          progress,
          errors,
          completed: total - succeed - progress - errors > 0,
        }

        this.results[name] = batchResult

        const target = document.getElementById(`batch-${name}`)
        if (!target) return
        target.textContent = `ドメイン数 ${batchResult.total} | 成功 ${batchResult.succeed} | 処理中 ${batchResult.progress} | 失敗 ${batchResult.errors}`
      })
    }
  }
}
