import { GraphQLMutationResult, GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const moveSiteValidation = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($location: String!) {
          satellitesiteMoveSiteValidation(location: $location) {
            sites {
              domain
              redirect
              errors
            }
          }
        }
      `,
      { location },
    ),
  ) as GraphQLQueryResult<'satellitesiteMoveSiteValidation'>

export const moveSiteExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteMoveSiteExecution(location: $location) {
            id
          }
        }
      `,
      { location },
    ),
  ) as GraphQLMutationResult<'satellitesiteMoveSiteExecution'>

export const listMoveSiteExecutions = (status?: Status, nextToken?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListMoveSiteExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      { status, nextToken },
    ),
  ) as GraphQLQueryResult<'satellitesiteListMoveSiteExecutions'>

export const describeMoveSiteExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeMoveSiteExecution(name: $name) {
            domain
            redirect
            progress
            succeed
            errors
          }
        }
      `,
      {
        name,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeMoveSiteExecution'>
