<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="7" class="text-subtitle-1 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">サイト移転</span>
        </v-col>
        <v-col class="text-right">
          <v-btn to="/satellite-site/batch/move/site">
            <v-icon icon="mdi-history" class="mr-1" />サイト移転の実行履歴
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="flex-row pt-0" fluid>
      <FileDrop @filedrop="store.filedrop" all>
        ここにアップロードするファイルをドラッグアンドドロップするか、<v-btn
          variant="outlined"
          size="small"
          class="d-inline-block mx-1 pa-0"
        >
          <label class="pa-2">
            ファイルを選択
            <input
              @change="store.onChangeFile"
              class="d-none"
              type="file"
              name="upload"
            /> </label></v-btn
        >をしてください。

        <v-card v-if="store.state.filedrop.file" class="mt-4" tile flat>
          <v-card-item class="d-flex align-center">
            <v-progress-circular
              class="d-flex mr-1"
              size="20"
              v-if="
                store.state.filedrop.progress < 100 ||
                store.state.filedrop.loading
              "
              :model-value="store.state.filedrop.progress"
              :indeterminate="store.state.filedrop.loading"
            />
            <v-icon
              v-else
              class="d-flex mr-1"
              size="20"
              :icon="
                store.state.filedrop.valid
                  ? 'mdi-check-circle-outline'
                  : 'mdi-close-circle-outline'
              "
              :color="store.state.filedrop.valid ? 'success' : 'error'"
            />
            <div class="d-flex">
              {{ store.state.filedrop.file.name }}
              （{{ store.state.filedrop.filesize }}）
            </div>
          </v-card-item>
        </v-card>

        <v-card
          v-if="store.state.filedrop.errors.length"
          class="mt-4"
          color="error"
          tile
          flat
        >
          <v-card-item
            v-for="error in store.state.filedrop.errors"
            :key="error"
          >
            <v-card-title>
              <v-icon icon="mdi-close-circle-outline" class="mr-1" />
              {{ error }}
            </v-card-title>
          </v-card-item>
        </v-card>
      </FileDrop>

      <v-card v-if="store.state.show.info" border tile flat>
        <v-card-title>
          移転対象のサイトリストをアップロードしてください
        </v-card-title>
        <v-card-item class="on-surface pa-4">
          <p class="mb-4">
            サイトリストは、Excelまたは、CSV形式で下記のフォーマットにしたがってください。
          </p>

          <ul class="ml-6 mb-4">
            <li>1カラム目に移転前のサテライトサイトURLを指定してください。</li>
            <li>2カラム目に移転後のサテライトサイトURLを指定してください。</li>
          </ul>

          <p class="my-4">例</p>

          <table border="1" cellspacing="0">
            <tr>
              <th class="pa-2 bg-secondary">移転前サイトURL</th>
              <th class="pa-2 bg-secondary">移転後サイトURL</th>
            </tr>
            <tr>
              <td class="pa-2">https://stellite-site.com</td>
              <td class="pa-2">https://new-stellite-site.com</td>
            </tr>
            <tr>
              <td class="pa-2">https://www.stellite-site.net</td>
              <td class="pa-2">https://www.new-stellite-site.net</td>
            </tr>
          </table>

          <p class="mt-4 text-success">
            <b
              >移転後サイトのドメインは、予めサテライトサイトの<router-link
                to="/satellite-site/domain/register"
                >ドメイン登録</router-link
              >を行っておく必要があります。</b
            >
          </p>
        </v-card-item>
      </v-card>

      <v-card
        v-if="store.state.show.result"
        :class="$style.result"
        :loading="store.state.result.loading"
        border
        tile
        flat
      >
        <v-card-item v-if="store.state.result.errors.length">
          <v-card-title
            v-for="error in store.state.result.errors"
            :key="error"
            class="text-error"
          >
            <v-icon
              icon="mdi-close-circle-outline"
              color="error"
              class="mr-1"
            />
            {{ error }}
          </v-card-title>
        </v-card-item>
        <v-card-actions v-else>
          <div class="pl-2">
            ドメインは<span class="pa-2 text-success font-weight-black"
              >{{ store.state.result.total }}件</span
            >あります。
          </div>
          <v-btn
            @click="store.clickExecution"
            color="primary"
            class="ml-auto px-6"
            :disabled="store.state.disabled.execution"
          >
            移転を実行する
          </v-btn>
        </v-card-actions>
        <v-card-item :class="$style.resultTable">
          <Table :table-key="resultTableKey" />
        </v-card-item>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import createStore from '@/store/satellite-site/move'
import FileDrop from '@/components/parts/FileDrop.vue'
import Table from '@/components/parts/Table.vue'

export default defineComponent({
  name: 'SatelliteSiteMove',
  components: {
    FileDrop,
    Table,
  },
  setup() {
    const store = inject(createStore.key, createStore)
    if (!store) throw new Error('Empty store')

    const resultTableKey = store.setup()

    return {
      store,
      resultTableKey,
    }
  },
})
</script>


<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.filelabel {
  font-weight: bold;
  padding: 4px;
  border-radius: 3px;
  background: rgba(var(--v-theme-success), var(--v-disabled-opacity));
}
</style>
