export const isFile = (entry: FileSystemEntry): entry is FileSystemFileEntry =>
  entry.isFile

export const isDirectory = (
  entry: FileSystemEntry,
): entry is FileSystemDirectoryEntry => entry.isDirectory

export const getFileFromEntry = async (file: FileSystemFileEntry) =>
  new Promise<File>((resolve, reject) => {
    file.file(
      (file) => {
        resolve(file)
      },
      (err) => {
        reject(err)
      },
    )
  })

export const getFilesFromEntries = async (entries: FileSystemEntry[]) => {
  const files: File[] = []
  // const errors: Error[] = []
  for (const fileEntry of entries) {
    if (!isFile(fileEntry)) continue
    try {
      const file = await getFileFromEntry(fileEntry)
      files.push(file)
    } catch (err) {
      const error = err as Error
      // errors.push(error)
      console.log(error)
    }
  }

  return files
}
