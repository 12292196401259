import { GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const getUploadLocation = () =>
  API.graphql(
    graphqlOperation(
      gql`
        query {
          satellitesiteGetUploadLocation {
            url
          }
        }
      `,
    ),
  ) as GraphQLQueryResult<'satellitesiteGetUploadLocation'>

export const getSiteTemplateLocation = (domain: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($domain: String!) {
          satellitesiteGetSiteTemplateLocation(domain: $domain) {
            url
          }
        }
      `,
      { domain },
    ),
  ) as GraphQLQueryResult<'satellitesiteGetSiteTemplateLocation'>

export const listPendingDomain = () =>
  API.graphql(
    graphqlOperation(
      gql`
        query {
          satellitesiteListPendingDomain {
            nameServers
            domains
          }
        }
      `,
    ),
  ) as GraphQLQueryResult<'satellitesiteListPendingDomain'>

export const listPendingDomainExecutions = (
  status?: Status,
  nextToken?: string,
) =>
  API.graphql(
    graphqlOperation(
      gql`
        query listPendingDomainExecutions($status: String, $nextToken: String) {
          satellitesiteListPendingDomain {
            nameServers
            domains
          }
          satellitesiteListRegisterDomainExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              startDate
              status
            }
            nextToken
          }
          satellitesiteListImportDomainExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              startDate
              status
            }
            nextToken
          }
        }
      `,
      {
        status,
        nextToken,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteListPendingDomain'> &
    GraphQLQueryResult<'satellitesiteListRegisterDomainExecutions'> &
    GraphQLQueryResult<'satellitesiteListImportDomainExecutions'>
