
import { defineComponent, inject } from 'vue'

import detailStore from '@/store/satellite-site/batch/register/domain/detail'

export default defineComponent({
  name: 'SatelliteSiteBatchRegisterDomainDetail',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    const store = inject(detailStore.key, detailStore)
    if (!store) {
      throw new Error('Empty store')
    }

    store.setup()

    return { store }
  },
})
