
import { defineComponent, onMounted, onBeforeUnmount, ref } from 'vue'
import { isFile, isDirectory } from '@/store/module/filedrop'

export default defineComponent({
  name: 'FileDrop',
  props: {
    /**
     * ファイルドロップを画面すべてで受け付ける
     */
    all: {
      type: Boolean,
      default: false,
    },

    /**
     * ファイルを受け付ける
     */
    file: {
      type: Boolean,
      default: true,
    },

    /**
     * ディレクトリを受け付ける
     */
    directory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const lastTarget = ref<EventTarget>()
    const dragging = ref<boolean>(false)

    const onDragEnter = (e: DragEvent) => {
      if (e.target) {
        lastTarget.value = e.target
        dragging.value = true
      }
    }

    const onDragLeave = (e: DragEvent) => {
      if (e.target === lastTarget.value) {
        dragging.value = false
      }
    }

    const onDragOver = (e: DragEvent) => {
      e.preventDefault()
    }

    const scanFiles = async (
      entry: FileSystemEntry,
      results: FileSystemEntry[],
    ) => {
      if (isFile(entry)) {
        results.push(entry)
      } else if (isDirectory(entry)) {
        const entryReader = entry.createReader()
        const entries = await new Promise<FileSystemEntry[]>((resolve) => {
          entryReader.readEntries((entries) => {
            resolve(entries)
          })
        })
        await Promise.all(
          entries.map((entry) => {
            scanFiles(entry, results)
          }),
        )
      }
    }

    const onDrop = async (e: DragEvent) => {
      e.preventDefault()
      dragging.value = false
      const items = e.dataTransfer?.items
      if (!items) return

      const entries: FileSystemEntry[] = []

      for (const item of items) {
        if (!item.webkitGetAsEntry) continue
        const entry = item.webkitGetAsEntry()
        if (!entry) continue
        entries.push(entry)
      }

      const results: FileSystemEntry[] = []
      for (const entry of entries) {
        if (props.file && isFile(entry)) {
          await scanFiles(entry, results)
        }

        if (props.directory && isDirectory(entry)) {
          await scanFiles(entry, results)
        }
      }

      context.emit('filedrop', results)
    }

    if (props.all) {
      onMounted(() => {
        window.addEventListener('dragenter', onDragEnter)
        window.addEventListener('dragleave', onDragLeave)
        window.addEventListener('dragover', onDragOver)
        window.addEventListener('drop', onDrop)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('dragenter', onDragEnter)
        window.removeEventListener('dragleave', onDragLeave)
        window.removeEventListener('dragover', onDragOver)
        window.removeEventListener('drop', onDrop)
      })
    }

    const noAction = () => {
      // nothing
    }

    return {
      dragging,
      lastTarget,
      onDragEnter: props.all ? noAction : onDragEnter,
      onDragLeave: props.all ? noAction : onDragLeave,
      onDragOver: props.all ? noAction : onDragOver,
      onDrop: props.all ? noAction : onDrop,
    }
  },
})
