<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="7" class="text-subtitle-1 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">サイト作成</span>
        </v-col>
        <v-col class="text-right">
          <v-btn to="/satellite-site/batch/build/site">
            <v-icon icon="mdi-history" class="mr-1" />サイト生成の実行履歴
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="flex-row pt-0" fluid>
      <FileDrop @filedrop="store.filedrop" all>
        <p>
          ここにアップロードするファイルをドラッグアンドドロップするか、<v-btn
            variant="outlined"
            size="small"
            class="d-inline-block mx-1 pa-0"
            ><label class="pa-2">
              ファイルを選択
              <input
                @change="store.onChangeFile"
                class="d-none"
                type="file"
                name="upload"
              /> </label></v-btn
          >をしてください。
        </p>

        <p class="mt-2 mb-2">
          対応するファイルフォーマットは、<span :class="$style.filelabel"
            >ドメイン.csv</span
          >
          <span :class="$style.filelabel" class="mx-2">記事.csv</span>
          <span :class="$style.filelabel">制作.csv</span>
          の形式です。
        </p>

        <p class="">
          サテライトサイトのテンプレートファイルは、Zip圧縮でフォルダ毎まとめてアップロードしてください。
        </p>

        <v-divider class="my-4" />

        <v-row>
          <v-col cols="2" class="align-self-center">
            <div class="d-flex align-center">
              <v-icon class="mb-1" icon="mdi-file-upload-outline" />
              <span>ドメイン.csv</span>
            </div>
          </v-col>
          <v-col class="align-self-center">
            <p
              v-if="store.state.filedrop.csv['domain']"
              class="d-flex text-success"
            >
              <v-icon icon="mdi-check-circle-outline" class="mr-1" />
              <span>{{ store.state.filedrop.csv.domain.file.name }}</span>
            </p>
            <div v-else class="d-flex text-warning">
              <v-icon icon="mdi-alert-circle-outline" /><span
                class="d-inline-block"
                >ファイルをアップロードしてください。</span
              >
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" class="align-self-center">
            <div class="d-flex align-center">
              <v-icon class="mb-1" icon="mdi-file-upload-outline" />
              <span>記事.csv</span>
            </div>
          </v-col>
          <v-col class="align-self-center">
            <p
              v-if="store.state.filedrop.csv['article']"
              class="d-flex text-success"
            >
              <v-icon icon="mdi-check-circle-outline" class="mr-1" />
              <span>{{ store.state.filedrop.csv.article.file.name }}</span>
            </p>
            <p v-else class="d-flex text-warning">
              <v-icon icon="mdi-alert-circle-outline" /><span
                >ファイルをアップロードしてください。</span
              >
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2" class="align-self-center">
            <div class="d-flex align-center">
              <v-icon class="mb-1" icon="mdi-file-upload-outline" />
              <span>制作.csv</span>
            </div>
          </v-col>
          <v-col class="align-self-center">
            <p
              v-if="store.state.filedrop.csv['production']"
              class="d-flex text-success"
            >
              <v-icon icon="mdi-check-circle-outline" class="mr-1" />
              <span>{{ store.state.filedrop.csv.production.file.name }}</span>
            </p>
            <p v-else class="d-flex text-warning">
              <v-icon icon="mdi-alert-circle-outline" /><span
                >ファイルをアップロードしてください。</span
              >
            </p>
          </v-col>
        </v-row>

        <div v-if="store.state.filedrop.files.length > 0">
          <v-divider class="mt-4" />
          <v-card
            v-for="file in store.state.filedrop.files"
            :key="file"
            class="mt-4"
            tile
            flat
          >
            <v-card-item class="d-flex align-center">
              <v-progress-circular
                class="d-flex mr-1"
                size="20"
                v-if="file.progress < 100 || file.loading"
                :model-value="file.progress"
                :indeterminate="file.loading"
              />
              <v-icon
                v-else
                class="d-flex mr-1"
                size="20"
                :icon="
                  file.valid
                    ? 'mdi-check-circle-outline'
                    : 'mdi-close-circle-outline'
                "
                :color="file.valid ? 'success' : 'error'"
              />
              <div class="d-flex">
                {{ file.file.name }}
                （{{ file.filesize }}）
                <span class="text-error">{{ file.error }}</span>
              </div>
            </v-card-item>
          </v-card>
        </div>

        <v-card
          v-if="store.state.filedrop.errors.length"
          class="mt-4"
          color="error"
          tile
          flat
        >
          <v-card-item
            v-for="error in store.state.filedrop.errors"
            :key="error"
          >
            <v-card-title>
              <v-icon icon="mdi-close-circle-outline" class="mr-1" />
              {{ error }}
            </v-card-title>
          </v-card-item>
        </v-card>
      </FileDrop>

      <v-card v-if="store.state.show.validation" tile>
        <v-card-title v-if="store.state.show.loading">
          <v-progress-circular
            v-if="store.state.show.loading"
            size="26"
            class="mr-2"
            indeterminate
          />検証中
        </v-card-title>
        <div class="mb-4" v-else>
          <v-card-title>
            <v-row no-gutters>
              <v-col class="d-flex align-center">作成内容</v-col>
              <v-col class="text-right">
                <v-btn
                  @click="store.buildSite"
                  color="primary"
                  :loading="store.state.show.submitLoading"
                  :disabled="store.state.disabled.submit"
                >
                  サイト作成を実行する
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mt-0 mb-4" />
          <div>
            <v-row class="text-bold">
              <v-col cols="3">
                <div class="pl-4"><b>ドメイン</b></div>
              </v-col>
              <!-- <v-col cols="1"><b>wwwあり/なし</b></v-col> -->
              <v-col><b>サイトタイトル</b></v-col>
              <!-- <v-col cols="2"><b>フォルダ名</b></v-col> -->
              <v-col><b>エラー</b></v-col>
            </v-row>
          </div>
          <div>
            <v-row v-for="result in store.state.results" :key="result">
              <v-col cols="3">
                <div class="pl-4">
                  <v-icon
                    size="20"
                    :icon="
                      result.error === null
                        ? 'mdi-check-circle-outline'
                        : 'mdi-close-circle-outline'
                    "
                    :color="result.error === null ? 'success' : 'error'"
                  />
                  {{ result.domain }}
                </div>
              </v-col>
              <!-- <v-col cols="1">{{ result.www ? 'あり' : 'なし' }}</v-col> -->
              <v-col>{{ result.title }}</v-col>
              <!-- <v-col cols="2">{{ result.dirname }}</v-col> -->
              <v-col
                ><span class="text-error">{{ result.error }}</span></v-col
              >
            </v-row>
          </div>
        </div>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import createStore from '@/store/satellite-site/create'
import FileDrop from '@/components/parts/FileDrop.vue'

export default defineComponent({
  name: 'SatelliteSiteCreate',
  components: {
    FileDrop,
  },
  setup() {
    const store = inject(createStore.key, createStore)
    if (!store) throw new Error('Empty store')

    store.setup()

    return {
      store,
    }
  },
})
</script>


<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.filelabel {
  font-weight: bold;
  padding: 4px;
  border-radius: 3px;
  background: rgba(var(--v-theme-success), var(--v-disabled-opacity));
}
</style>
