<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="7" class="text-subtitle-1 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">未設定ドメイン</span>
        </v-col>

        <v-col class="text-right">
          <v-btn to="/satellite-site/domain/register">
            <v-icon icon="mdi-dns-outline" class="mr-1" />
            新しいドメインを登録する
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-circular
      v-if="store.state.show.loading"
      indeterminate
      class="mx-3 mb-4"
    />

    <v-container v-if="store.state.show.result" class="flex-row pt-0" fluid>
      <v-row>
        <v-col class="text-left">
          ネームサーバーが未設定のドメインリストです。
        </v-col>

        <v-col id="action-buttons" class="text-right">
          <v-icon
            v-if="store.state.extension.signal"
            icon="mdi-link-variant"
            size="22"
          />
          <v-btn
            id="reload-button"
            @click="store.reload"
            color="secondary"
            flat
            tile
            elevation="0"
            :disabled="store.state.disabled.reload"
            ><v-icon icon="mdi-reload"
          /></v-btn>
        </v-col>
      </v-row>

      <v-card v-for="result in store.state.results" :key="result" tile>
        <v-card-item class="pa-4">
          <v-row>
            <v-col cols="6" :class="$style.nameserver" class="copy-nameserver">
              <div class="text-subtitle-2">ネームサーバー1</div>
              <span>{{ result.nameServers[0] }}</span>
              <v-icon icon="mdi-clipboard-text-outline" class="ml-2" />
            </v-col>
            <v-col cols="6" :class="$style.nameserver" class="copy-nameserver">
              <div class="text-subtitle-2">ネームサーバー2</div>
              <span>{{ result.nameServers[1] }}</span>
              <v-icon icon="mdi-clipboard-text-outline" class="ml-2" />
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-item class="pa-4 pt-0">
          <v-row>
            <v-col cols="6" :class="$style.domains" class="copy-domains">
              <div class="text-subtitle-2">
                ドメイン数 {{ result.domains.length }}個
                <v-icon icon="mdi-clipboard-text-outline" />
              </div>
              <textarea
                v-bind:value="result.domains.join('\n')"
                :class="$style.textarea"
                unselectable="on"
                readonly
              />
            </v-col>
            <v-col cols="6">
              <div class="text-subtitle-2">確認時間</div>
              <div class="">
                {{ new Date().toLocaleString() }}
              </div>
              <div v-if="store.state.tasks.length">
                <v-row>
                  <v-col><div class="text-subtitle-2 pt-4">処理中</div></v-col>
                </v-row>
                <div v-for="task in store.state.tasks" :key="task">
                  <v-row dense>
                    <v-col>{{ task.name }}</v-col>
                    <v-col>
                      <span
                        class="
                          float-left
                          text-subtitle-2
                          rounded
                          bg-info
                          pa-1
                          px-2
                          mb-2
                        "
                        >ドメイン数 {{ task.domains.length }}</span
                      >
                      <span
                        class="
                          float-left
                          text-subtitle-2
                          rounded
                          bg-success
                          pa-1
                          px-2
                          mx-2
                          mb-2
                        "
                        >完了 {{ task.progress }}%</span
                      >
                      <span
                        v-if="task.errors.length > 0"
                        class="
                          float-left
                          text-subtitle-2
                          rounded
                          bg-warning
                          pa-1
                          px-2
                          mb-2
                        "
                        >処理遅延中</span
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row>
                <v-col>
                  <v-btn
                    to="/satellite-site/batch/register/domain"
                    variant="outlined"
                    class="mt-2"
                  >
                    <v-icon
                      icon="mdi-history"
                      class="mr-1"
                    />新しいドメイン登録の実行履歴
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>

    <v-container v-if="store.state.show.empty" class="flex-row pt-0" fluid>
      <v-card tile>
        <v-card-item>
          <v-row>
            <v-col class="text-left">
              <p class="pa-4">未設定のドメインはありません。</p>
            </v-col>
            <v-col id="action-buttons" class="text-right align-self-center">
              <v-btn
                id="reload-button"
                class="ma-2"
                @click="store.reload"
                flat
                tile
                elevation="0"
                :disabled="store.state.disabled.reload"
              >
                <v-icon icon="mdi-reload" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>

    <v-container v-if="store.state.tasks.length" class="flex-row pt-0" fluid>
      <v-card v-for="task in store.state.tasks" :key="task" class="pb-2" tile>
        <v-card-item class="pa-2 pb-0">
          <v-row no-gutters>
            <v-col>
              <div class="pt-2 pl-2">
                <b>バッチ処理</b>
                <span class="text-subtitle-2 rounded bg-info pa-1 px-2 ml-2"
                  >ドメイン数 {{ task.domains.length }}</span
                >
                <span
                  :title="`成功 ${task.succeed}`"
                  class="text-subtitle-2 rounded bg-success pa-1 px-2 mx-2"
                  >完了 {{ task.progress }}%</span
                >
                <span
                  title="時間あたりのDNS変更処理数に制限がかかっており、ゆっくり処理しています。"
                  v-if="task.errors.length > 0"
                  class="text-subtitle-2 rounded bg-warning pa-1 px-2"
                  >処理遅延中</span
                >
                <span class="text-caption pa-1 px-2">
                  {{ task.startDate.toLocaleString() }}
                  {{ task.name }}
                </span>
              </div>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn @click="store.taskExpandToggle" flat tile elevation="0">
                <v-icon
                  :icon="
                    store.state.show.taskExpand
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
              /></v-btn>
            </v-col>
          </v-row>
        </v-card-item>

        <v-card-item v-if="store.state.show.taskExpand">
          <v-divider class="ma-2" />
          <v-row v-for="row in task.domains" :key="row" no-gutters>
            <v-col>
              <div class="px-4">
                <v-icon
                  :icon="row.succeed ? 'mdi-check-bold' : 'mdi-timer-sand'"
                  :color="row.succeed ? 'success' : 'seconda-darken-1'"
                />
                {{ row.domain }}
              </div>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>

    <v-container v-if="store.state.show.request" class="flex-row pt-0" fluid>
      <v-card class="pb-2" tile>
        <v-card-title>
          お名前.com ネームサーバー変更
          <v-progress-circular
            v-if="store.state.extension.loading"
            class="mx-2"
            size="22"
            indeterminate
          />
        </v-card-title>
        <v-card-item
          v-for="domain in store.state.extension.domains"
          :key="domain"
        >
          <v-row dense>
            <v-col cols="4" class="text-left">
              <div class="px-4">{{ domain.domain }}</div>
            </v-col>
            <v-col cols="1" class="align-self-center">
              <v-progress-circular
                v-if="domain.progress"
                size="22"
                indeterminate
              />
              <v-icon
                v-else
                :icon="domain.succeed ? 'mdi-check-bold' : 'mdi-close-thick'"
                :color="domain.succeed ? 'success' : 'error'"
                size="22"
              />
            </v-col>
            <v-col cols="7" class="text-left">
              {{ domain.error }}
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>

    <div class="extension-control d-none">
      <input
        id="nameserver-json"
        type="hidden"
        v-bind:value="store.state.json"
        :data-loading="store.state.show.loading"
      />
      <input
        id="task-remain"
        type="hidden"
        v-bind:value="store.state.tasks.length"
      />
      <button
        id="extension-signal-on"
        type="hidden"
        @click="store.extensionSignalOn"
      />
      <button
        id="extension-signal-off"
        type="hidden"
        @click="store.extensionSignalOff"
      />
      <button
        id="extension-show-request"
        type="hidden"
        @click="store.extensionShowRequest"
      />
      <input id="extension-request-result" type="hidden" />
      <button
        id="extension-update-request-result"
        type="hidden"
        @click="store.extensionUpdateRequestResult"
      />
      <button
        id="extension-request-completed"
        type="hidden"
        @click="store.extensionRequestComplated"
      />
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import store from '@/store/satellite-site/pending-zones'

export default defineComponent({
  name: 'SatelliteSiteIndex',
  setup() {
    store.setup()

    return {
      store,
    }
  },
})
</script>


<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.nameserver {
  cursor: pointer;
  &:hover {
    background: rgba(var(--v-theme-on-background), var(--v-hover-opacity));
  }
}
.domains {
  cursor: pointer;
  &:hover {
    background: rgba(var(--v-theme-on-background), var(--v-hover-opacity));
  }
}
.textarea {
  cursor: pointer;
  width: 100%;
  padding: 0;
  outline: none;
  min-height: 7.5rem;
  user-select: none;
}
</style>
