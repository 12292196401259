import { isGraphQLError, Types } from '@/graphql'
import {
  describeUpdateSiteExecution,
  getSiteTemplateLocation,
  getUploadLocation,
  loadSiteTemplate,
  searchDomain,
  updateSiteTemplateExecution,
  uploadSiteTemplateFile,
  updateSiteFilesExecution,
} from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { getFilesFromEntries } from '@/store/module/filedrop'
import axios from 'axios'
import { debounce } from 'lodash'
import prettyBytes from 'pretty-bytes'
import { InjectionKey, onMounted, reactive } from 'vue'

interface Show {
  search: boolean
  searchLoading: boolean
  templateLoading: boolean
  templateUploadLoading: boolean
  uploadLoading: boolean
  downloadLoading: boolean
  uploadTemplateArea: boolean
  info: boolean
  result: boolean
}

interface Disabled {
  templateUploadButton: boolean
  templateDownloadButton: boolean
  templateUploadSubmitButton: boolean
  updateButton: boolean
}

interface FileDrop {
  errors: string[]
  loading: boolean
  progress: number
  valid: boolean
  file?: File
  filesize?: string
}

interface Result {
  total: number
  errors: string[]
  loading: boolean
  location?: string
}

interface Search {
  input: string
  suggests: string[]
  equal: boolean
}

interface Resource {
  file: string
  content?: string | null
  location?: string | null
  delete?: boolean | null
  uploadLocation?: string
  dataUrl?: string
}

interface OriginResource extends Resource {
  dirty: boolean
}

interface Editer {
  dirty: boolean
  selectResource: string
  delete: boolean
  deletable: boolean
  content: string
  location: string
  dataUrl: string
  resource: {
    origins: OriginResource[]
    updates: Resource[]
  }
}

interface State {
  show: Show
  disabled: Disabled
  editor: Editer
  search: Search
  result: Result
  filedropImage: FileDrop
  filedropTemplate: FileDrop
}

const state = reactive<State>({
  show: {
    search: true,
    searchLoading: false,
    templateLoading: false,
    templateUploadLoading: false,
    uploadLoading: false,
    downloadLoading: false,
    uploadTemplateArea: false,
    info: true,
    result: false,
  },
  disabled: {
    templateUploadButton: true,
    templateDownloadButton: true,
    templateUploadSubmitButton: true,
    updateButton: true,
  },
  editor: {
    dirty: false,
    delete: false,
    deletable: false,
    content: '',
    location: '',
    dataUrl: '',
    selectResource: '',
    resource: {
      origins: [],
      updates: [],
    },
  },
  search: {
    input: '',
    suggests: [],
    equal: false,
  },
  result: {
    total: 0,
    loading: false,
    errors: [],
  },
  filedropImage: {
    progress: 0,
    loading: false,
    valid: false,
    errors: [],
  },
  filedropTemplate: {
    progress: 0,
    loading: false,
    valid: false,
    errors: [],
  },
})

let previousInput: string
const resetState = () => {
  previousInput = ''
  state.disabled.templateUploadButton = true
  state.disabled.templateDownloadButton = true
  state.disabled.templateUploadSubmitButton = true
  state.disabled.updateButton = true
  state.editor.dirty = false
  state.editor.delete = false
  state.editor.deletable = false
  state.editor.content = ''
  state.editor.location = ''
  state.editor.dataUrl = ''
  state.editor.selectResource = ''
  state.editor.resource.origins = []
  state.editor.resource.updates = []
  state.filedropImage.errors = []
  state.filedropImage.file = undefined
  state.filedropImage.filesize = undefined
  state.filedropImage.loading = false
  state.filedropImage.progress = 0
  state.filedropImage.valid = false
  state.filedropTemplate.errors = []
  state.filedropTemplate.file = undefined
  state.filedropTemplate.filesize = undefined
  state.filedropTemplate.loading = false
  state.filedropTemplate.progress = 0
  state.filedropTemplate.valid = false
  state.result.errors = []
  state.result.location = undefined
  state.search.input = ''
  state.search.suggests = []
  state.show.info = true
  state.show.result = false
  state.show.templateLoading = false
  state.show.templateUploadLoading = false
  state.show.uploadLoading = false
  state.show.uploadTemplateArea = false
}

const setup = () => {
  onMounted(async () => {
    resetState()
  })
}

const readAsDataURL = (file: File) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.onload = (e: Event) => {
      const r = e.target as FileReader
      resolve(r.result as string)
    }
    reader.readAsDataURL(file)
  })

const resetFiledropTemplate = () => {
  state.filedropTemplate.errors = []
  state.filedropTemplate.file = undefined
  state.filedropTemplate.filesize = undefined
  state.filedropTemplate.progress = 0
  state.filedropTemplate.loading = false
  state.filedropTemplate.valid = false
  state.disabled.templateUploadSubmitButton = true
}

const waitUpdateSiteExecution = (id: string) =>
  new Promise<boolean>((resolve, reject) => {
    const describe = async (id: string, num = 0) => {
      const delaySec = num === 0 ? 8 : 3

      setTimeout(async () => {
        try {
          const ret = await describeUpdateSiteExecution(id)
          const result = ret.data?.satellitesiteDescribeUpdateSiteExecution
          console.log(result)

          if (!result) throw new Error('Failed describe')
          if (result.status === 'SUCCEEDED') {
            resolve(true)
          } else if (result.status === 'RUNNING') {
            await describe(id, num++)
          } else {
            throw new Error('Failed update site execution')
          }
        } catch (e) {
          console.log(e)
          reject(e)
        }
      }, delaySec * 1000)
    }

    describe(id)
  })

const setTemplateFiles = async (files: File[]) => {
  resetFiledropTemplate()

  const file = files[0]

  if (!/\.(zip)$/.test(file.name)) {
    state.filedropTemplate.errors.push('対応していないファイル形式です。')
    state.filedropTemplate.loading = false
    return
  }

  state.filedropTemplate.file = file
  state.filedropTemplate.loading = true
  state.filedropTemplate.filesize = prettyBytes(file.size)

  const location = await getUploadLocation()
  const url = location.data?.satellitesiteGetUploadLocation.url
  if (!url) {
    state.filedropTemplate.loading = false
    throw new Error('Failed getUploadLocation')
  }

  // ファイルのアップロード
  try {
    await axios.put(url, file, {
      onUploadProgress: (e: ProgressEvent) => {
        state.filedropTemplate.progress = (e.loaded / e.total) * 100
      },
    })
    const location = new URL(url).pathname.split('/').pop()
    if (!location) throw new Error('Failed url parse')

    const ret = await uploadSiteTemplateFile(state.search.input, location)
    const result = ret.data?.satellitesiteUploadSiteTemplateFile

    state.result.location = result?.location ?? undefined
    state.disabled.templateUploadSubmitButton = !state.result.location

    if (result?.error) {
      switch (result.error) {
        case 'No such template':
          state.filedropTemplate.errors.push(
            'テンプレートファイルが見つかりませんでした。',
          )
          break
        default:
          state.filedropTemplate.errors.push(result.error)
          break
      }
    }
  } catch (err) {
    state.filedropTemplate.errors.push('アップロードに失敗しました。')
    state.filedropTemplate.valid = false
    console.log(err)
  } finally {
    state.filedropTemplate.loading = false
  }
}

const setImageFiles = async (files: File[]) => {
  state.filedropImage.errors = []
  state.filedropImage.file = undefined
  state.filedropImage.filesize = undefined
  state.filedropImage.progress = 0
  state.filedropImage.loading = true
  state.filedropImage.valid = false
  state.show.info = true
  state.show.result = false

  const file = files[0]

  if (!/\.(jpe?g|png|gif|webp)$/.test(file.name)) {
    state.filedropImage.errors.push('対応していないファイル形式です。')
    state.filedropImage.loading = false
    return
  }

  state.filedropImage.file = file
  state.filedropImage.filesize = prettyBytes(file.size)
  const dataUrl = await readAsDataURL(file)
  const location = await getUploadLocation()
  const url = location.data?.satellitesiteGetUploadLocation.url
  if (!url) {
    state.filedropImage.loading = false
    throw new Error('Failed getUploadLocation')
  }

  // ファイルのアップロード
  try {
    await axios.put(url, file, {
      onUploadProgress: (e: ProgressEvent) => {
        state.filedropImage.progress = (e.loaded / e.total) * 100
      },
    })
    const location = new URL(url).pathname.split('/').pop()
    if (!location) throw new Error('Failed url parse')

    const target = state.editor.resource.origins.find(
      (f) => f.file === state.editor.selectResource,
    )
    if (!target || !target.location) throw new Error('No such resource')

    const updateTarget = state.editor.resource.updates.find(
      (f) => f.file === state.editor.selectResource,
    )
    if (updateTarget) {
      updateTarget.location = target.location
      updateTarget.uploadLocation = location
      updateTarget.dataUrl = dataUrl
    } else {
      state.editor.resource.updates.push({
        file: state.editor.selectResource,
        location: target.location,
        uploadLocation: location,
        dataUrl,
        delete: false,
      })
    }

    target.dirty = true
    state.editor.dataUrl = dataUrl
    state.editor.delete = false
    state.editor.dirty = true
    state.disabled.updateButton = false
  } catch (err) {
    state.filedropImage.errors.push('アップロードに失敗しました。')
    state.filedropImage.valid = false
    console.log(err)
  } finally {
    state.filedropImage.loading = false
  }
}

const filedropTemplate: FileSystemEntriesCallback = async (entries) => {
  const files = await getFilesFromEntries(entries)
  await setTemplateFiles(files)
}

const filedropImage: FileSystemEntriesCallback = async (entries) => {
  const files = await getFilesFromEntries(entries)
  await setImageFiles(files)
}

const onChangeImageFile = async (e: Event) => {
  const target = e.target as HTMLInputElement
  if (target.files) await setImageFiles(Array.from(target.files))
}

const loadTemplate = async (domain: string, noCache?: boolean) => {
  document.getElementById('template-editor-search-input')?.blur()

  state.show.templateLoading = true
  state.disabled.updateButton = true
  state.result.errors = []

  try {
    const result = await loadSiteTemplate(domain, noCache)
    const files = result.data?.satellitesiteLoadSiteTemplate

    if (!files || files.length === 0) {
      state.result.errors.push('テンプレートデータの読み込みに失敗しました。')
      return
    }

    setEditorContent(files[0])

    state.result.errors = []
    state.editor.selectResource = files[0].file
    state.editor.delete = false
    state.editor.deletable = false
    state.disabled.templateUploadButton = false
    state.disabled.templateDownloadButton = false

    state.editor.resource.updates = []
    state.editor.resource.origins = files.map((f) => {
      return { ...f, dirty: false, delete: false }
    })
  } catch (err) {
    if (isGraphQLError(err)) {
      const notAvailable = err.errors?.find(
        (error) => 'Not available domain' === error.message,
      )

      if (notAvailable) {
        state.result.errors.push(
          'ドメインのサイトが見つかりませんでした。',
          'ドメイン名が間違っていないか、ドメイン登録ができているかご確認ください。',
        )
      }

      // サイトテンプレートはないが、テンプレートをアップロードすることが可能な状態
      const templateUploadable = err.errors?.find(
        (error) => 'No such template. template uploadable' === error.message,
      )
      if (templateUploadable) {
        state.disabled.templateUploadButton = false
        state.result.errors.push(
          'このドメインのサイトは、テンプレートがまだありません。',
          'テンプレートをアップロードすることで、サイトを生成することができます。',
        )
      }
    }
  } finally {
    state.show.templateLoading = false
  }
}

const getInputDomain = () => {
  return state.search.input
    .replace(/^https?:\/\//, '')
    .replace(/^www\./, '')
    .split('/', 2)[0]
}

let stopKeyInput = false
const onChangeInput = async () => {
  if (state.search.input.length > 0) {
    stopKeyInput = true
    const domain = getInputDomain()
    try {
      await loadTemplate(domain)
    } catch (e) {
      console.log(e)
    }
    stopKeyInput = false
  }
}

const onKeyupInput = (e: KeyboardEvent) => {
  if (stopKeyInput) return

  if (/^Arrow/.test(e.code)) {
    return
  }

  if (state.search.input.length <= 0) {
    state.search.suggests = []
    state.editor.resource.origins = []
    state.editor.resource.updates = []
    state.editor.selectResource = ''
    state.editor.delete = false
    state.editor.deletable = false

    previousInput = ''
    return
  }

  const domain = getInputDomain()

  if (previousInput === domain) return

  previousInput = domain

  debounce(
    async () => {
      state.show.searchLoading = true
      state.disabled.templateUploadButton = true
      state.disabled.templateDownloadButton = true

      const result = await searchDomain(domain, 'prefix')
      state.search.suggests = result.data?.satellitesiteSearchDomain ?? []

      // 完全一致
      state.search.equal =
        state.search.suggests.length === 1 &&
        domain === state.search.suggests[0]

      state.show.searchLoading = false

      if (state.search.equal) {
        state.search.suggests = []
        loadTemplate(domain)
      } else {
        state.editor.resource.origins = []
        state.editor.resource.updates = []
      }
    },
    500,
    { leading: false, trailing: true },
  )()
}

const onKeyupSelectContent = (e: KeyboardEvent) => {
  if (/^Arrow/.test(e.code)) {
    return
  }

  const selectResource = state.editor.selectResource
  const content = state.editor.content

  const target = state.editor.resource.origins.find(
    (f) => f.file === selectResource,
  )
  if (!target || !target.content) throw new Error('No such resource')

  const updateTarget = state.editor.resource.updates.find(
    (f) => f.file === selectResource,
  )

  const editorContent = content.replace(/(\r\n|\r)/g, '\n')
  const targetContent = target.content.replace(/(\r\n|\r)/g, '\n')

  if (editorContent !== targetContent) {
    state.editor.dirty = true
    target.dirty = true
    if (updateTarget) {
      updateTarget.content = content
    } else {
      state.editor.resource.updates.push({ file: target.file, content })
    }
  } else if (updateTarget) {
    target.dirty = false
    state.editor.resource.updates = state.editor.resource.updates.filter(
      (f) => f.file !== selectResource,
    )

    state.editor.dirty = state.editor.resource.updates.length > 0
  }

  state.disabled.updateButton = !state.editor.dirty
}

const onKeypressSelectContent = (e: KeyboardEvent) => {
  if (e.code === 'Backspace') {
    clickDeleteFile()
  }
}

const clickDownloadTemplate = async () => {
  state.show.downloadLoading = true

  const domain = state.search.input
  const ret = await getSiteTemplateLocation(domain)
  const url = ret.data?.satellitesiteGetSiteTemplateLocation.url
  if (!url) throw new Error('No such template file')

  const a = document.createElement('a')
  a.href = url
  a.download = `${domain}.zip`
  document.body.appendChild(a)
  a.click()
  a.parentNode?.removeChild(a)

  state.show.downloadLoading = false
}

const clickUpdateSiteFiles = async () => {
  if (state.show.uploadLoading) return

  state.show.uploadLoading = true
  const domain = state.search.input
  const files =
    state.editor.resource.updates.map<Types.SatelliteSiteUpdateSiteFile>(
      (f) => {
        if (f.delete === true) {
          return { file: f.file, delete: true }
        }

        if (f.uploadLocation) {
          return { file: f.file, location: f.uploadLocation }
        }

        return { file: f.file, content: f.content }
      },
    )

  try {
    console.log({ domain, files })

    const ret = await updateSiteFilesExecution(domain, files)
    const id = ret.data?.satellitesiteUpdateSiteFilesExecution.id
    if (!id) throw new Error('No such id')

    await waitUpdateSiteExecution(id)
    await loadTemplate(domain, true)
  } catch (e) {
    console.log(e)
  } finally {
    state.show.uploadLoading = false
  }
}

const clickUpdateSiteTemplate = async () => {
  if (state.show.templateUploadLoading) return
  const domain = state.search.input
  const location = state.result.location
  if (!location) throw new Error('No such location')

  state.show.templateUploadLoading = true
  state.disabled.templateUploadSubmitButton = true

  state.filedropTemplate.errors = []

  console.log('clickUpdateSiteTemplate', { domain, location })

  try {
    const ret = await updateSiteTemplateExecution(domain, location)
    const id = ret.data?.satellitesiteUpdateSiteTemplateExecution?.id
    if (!id) throw new Error('No such id')

    await waitUpdateSiteExecution(id)
    hideUploadTemplateArea()
    await loadTemplate(domain, true)
  } catch (e) {
    console.log(e)
    state.filedropTemplate.errors.push('アップロードに失敗しました。')
  } finally {
    state.show.templateUploadLoading = false
  }
}

const clickCancelUploadImage = () => {
  const target = state.editor.resource.origins.find(
    (f) => f.file === state.editor.selectResource,
  )
  if (!target) return

  target.dirty = false

  state.editor.dataUrl = ''
  state.editor.resource.updates = state.editor.resource.updates.filter(
    (f) => f.file !== state.editor.selectResource,
  )
  state.editor.dirty = state.editor.resource.updates.length > 0
  state.disabled.updateButton = !state.editor.dirty
}

const clickDeleteFile = () => {
  if (!isDeletableResource()) return

  const target = state.editor.resource.origins.find(
    (f) => f.file === state.editor.selectResource,
  )
  if (!target) return

  target.delete = !target.delete
  state.editor.delete = target.delete

  if (target.delete) {
    state.editor.dataUrl = ''
    state.editor.content = ''

    const updateTarget = state.editor.resource.updates.find(
      (f) => f.file === state.editor.selectResource,
    )

    if (updateTarget) {
      updateTarget.delete = true
      if (updateTarget['content']) delete updateTarget['content']
      if (updateTarget['location']) delete updateTarget['location']
      if (updateTarget['dataUrl']) delete updateTarget['dataUrl']
    } else {
      target.dirty = false
      state.editor.resource.updates.push({
        file: target.file,
        delete: true,
      })
    }
  } else {
    target.dirty = false
    state.editor.content = target.content ?? ''
    state.editor.resource.updates = state.editor.resource.updates.filter(
      (f) => f.file !== state.editor.selectResource,
    )
  }

  state.editor.dirty = state.editor.resource.updates.length > 0
  state.disabled.updateButton = !state.editor.dirty
}

const isDeletableResource = () => {
  if (state.editor.selectResource) {
    state.editor.deletable =
      /^content\/page_content[2-9]\.php$/.test(state.editor.selectResource) ||
      /^images\//.test(state.editor.selectResource)
  }

  return state.editor.deletable
}

const changeSelectResource = () => {
  isDeletableResource()

  const updateTarget = state.editor.resource.updates.find(
    (f) => f.file === state.editor.selectResource,
  )

  if (updateTarget) {
    setEditorContent(updateTarget)
    return
  }

  const target = state.editor.resource.origins.find(
    (f) => f.file === state.editor.selectResource,
  )

  if (!target) throw new Error('No such recourse')

  setEditorContent(target)
}

const changeSelectContent = () => {
  // console.log('changeSelectContent')
}

const setEditorContent = (resource: Resource) => {
  if (resource.content) {
    state.editor.content = resource.content
    state.editor.location = ''
  } else if (resource.location) {
    state.editor.content = ''
    state.editor.location = resource.location
    state.editor.dataUrl = resource.dataUrl ?? ''
  } else if (resource.delete === true) {
    state.editor.content = ''
  }

  state.editor.delete = state.editor.deletable && resource.delete === true
}

const showUploadTemplateArea = () => {
  state.show.uploadTemplateArea = true
  resetFiledropTemplate()
}

const hideUploadTemplateArea = () => {
  state.show.uploadTemplateArea = false
}

interface Store {
  state: State
  setup: () => void
  filedropImage: FileSystemEntriesCallback
  filedropTemplate: FileSystemEntriesCallback
  onChangeImageFile: (e: Event) => void
  onKeyupInput: (e: KeyboardEvent) => void
  onChangeInput: () => void
  onKeyupSelectContent: (e: KeyboardEvent) => void
  onKeypressSelectContent: (e: KeyboardEvent) => void
  clickDownloadTemplate: () => void
  clickCancelUploadImage: () => void
  clickDeleteFile: () => void
  changeSelectResource: () => void
  changeSelectContent: () => void
  clickUpdateSiteFiles: () => void
  clickUpdateSiteTemplate: () => void
  showUploadTemplateArea: () => void
  hideUploadTemplateArea: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  filedropImage,
  filedropTemplate,
  onChangeImageFile,
  onKeyupInput,
  onChangeInput,
  onKeyupSelectContent,
  onKeypressSelectContent,
  clickDownloadTemplate,
  clickCancelUploadImage,
  clickDeleteFile,
  changeSelectResource,
  changeSelectContent,
  clickUpdateSiteFiles,
  clickUpdateSiteTemplate,
  showUploadTemplateArea,
  hideUploadTemplateArea,
}

export default store
