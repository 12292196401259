
import { defineComponent, inject } from 'vue'
import createStore from '@/store/satellite-site/move'
import FileDrop from '@/components/parts/FileDrop.vue'
import Table from '@/components/parts/Table.vue'

export default defineComponent({
  name: 'SatelliteSiteMove',
  components: {
    FileDrop,
    Table,
  },
  setup() {
    const store = inject(createStore.key, createStore)
    if (!store) throw new Error('Empty store')

    const resultTableKey = store.setup()

    return {
      store,
      resultTableKey,
    }
  },
})
