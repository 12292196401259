<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="3" class="text-subtitle-1 pr-0 font-weight-bold">
          サテライトサイト
        </v-col>
        <v-col cols="9" class="text-right"> </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SatelliteSiteIndex',
})
</script>


<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
</style>
