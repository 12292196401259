
import { defineComponent, inject } from 'vue'

import importStore from '@/store/satellite-site/import'

import FileDrop from '@/components/parts/FileDrop.vue'
import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteImportIndex',
  components: {
    FileDrop,
    Table,
  },
  setup() {
    const store = inject(importStore.key, importStore)
    if (!store) {
      throw new Error('Empty store')
    }

    const resultTableKey = store.setup()

    return { store, resultTableKey }
  },
})
