import { BaseStore } from '@/store'
import { KeyPair } from '@/store/module/table'
import { InjectionKey, reactive, onUnmounted } from 'vue'
import { BatchTable } from './batch-table'

const batchTable = new BatchTable()

interface Show {
  detail: boolean
}

interface Disabled {
  reload: boolean
}

interface State {
  show: Show
  errors: string[]
  disabled: Disabled
}

const state = reactive<State>({
  show: {
    detail: false,
  },
  errors: [],
  disabled: {
    reload: false,
  },
})

const reload = async () => {
  // await requestListDomainExecutions()
}

const setup = () => {
  const keys = batchTable.setup('satellite-site-batch-list')

  onUnmounted(() => {
    batchTable.reset()
  })

  return keys
}

interface Store {
  state: State
  setup: () => KeyPair
  reload: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  reload,
}

export default store
