import { describeMoveSiteExecution } from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { InjectionKey, onMounted, reactive } from 'vue'
import { Router, useRouter } from 'vue-router'

interface Show {
  loading: boolean
  result: boolean
}

interface Disabled {
  reload: boolean
}

interface DomainResult {
  domain: string
  redirect: string
  progress: boolean
  succeed: boolean
  errors?: string[] | null
}

interface Result {
  total: number
  succeed: number
  progress: number
  errors: number
  completed: boolean
  domains: DomainResult[]
}

type Filter = 'all' | 'completed' | 'progress' | 'error'

interface State {
  id: string
  location: string
  show: Show
  filter: Filter
  disabled: Disabled
  result: Result
}

const state = reactive<State>({
  id: '',
  location: '',
  show: {
    loading: false,
    result: false,
  },
  filter: 'all',
  disabled: {
    reload: false,
  },
  result: {
    total: 0,
    succeed: 0,
    progress: 0,
    errors: 0,
    completed: false,
    domains: [],
  },
})

let router: Router
let reloadTimer: NodeJS.Timer
const reloadInterval = 10 * 1000

const setup = () => {
  router = useRouter()

  onMounted(async () => {
    resetState()
    await requestDescribeExecution()
  })
}

const resetState = () => {
  state.filter = 'all'
  state.show.loading = false
  state.show.result = false
  state.disabled.reload = false
  state.result.total = 0
  state.result.succeed = 0
  state.result.progress = 0
  state.result.errors = 0
  state.result.completed = false
  state.result.domains = []
}

const requestDescribeExecution = async () => {
  if (reloadTimer) {
    clearInterval(reloadTimer)
  }

  if (state.disabled.reload) return

  state.show.loading = true
  const id = router.currentRoute.value.params.id as string
  if (state.id !== id) {
    state.show.result = false
    state.show.loading = true
  }

  state.id = id

  const result = await describeMoveSiteExecution(id)
  const describe = result.data?.satellitesiteDescribeMoveSiteExecution
  if (!describe) {
    state.show.loading = false
    state.disabled.reload = false
    return
  }

  state.result.domains = []
  for (const result of describe) {
    state.result.domains.push(result)
  }

  state.result.total = describe.length
  state.result.succeed = state.result.domains.filter((d) => d.succeed).length
  state.result.progress = state.result.domains.filter((d) => d.progress).length
  state.result.errors = state.result.domains.filter(
    (d) => d.errors && d.errors.length,
  ).length

  state.show.result = true
  state.show.loading = false
  state.disabled.reload = false

  if (state.result.progress > 0) {
    reloadTimer = setInterval(() => {
      reload()
    }, reloadInterval)
  }
}

const reload = async () => {
  await requestDescribeExecution()
}

const clickFilterAll = async () => {
  state.filter = 'all'
}

const clickFilterCompleted = async () => {
  state.filter = 'completed'
}

const clickFilterProgress = async () => {
  state.filter = 'progress'
}

const clickFilterError = async () => {
  state.filter = 'error'
}

interface Store {
  state: State
  setup: () => void
  reload: () => void
  clickFilterAll: () => void
  clickFilterCompleted: () => void
  clickFilterProgress: () => void
  clickFilterError: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  reload,
  clickFilterAll,
  clickFilterCompleted,
  clickFilterProgress,
  clickFilterError,
}

export default store
