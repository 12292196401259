import {
  addTemplate,
  buildFileSetValidation,
  buildFileValidation,
  buildSiteExecution,
  getUploadLocation,
} from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { getFilesFromEntries } from '@/store/module/filedrop'
import { CsvType } from '@satellite-site/build'
import axios from 'axios'
import prettyBytes from 'pretty-bytes'
import { InjectionKey, reactive } from 'vue'
import { Router, useRouter } from 'vue-router'

interface Show {
  validation: boolean
  loading: boolean
  submitLoading: boolean
}

interface Disabled {
  submit: boolean
}

interface FileDropFile {
  loading: boolean
  valid: boolean
  progress: number
  error: string
  file: File
  filesize: string
  type?: CsvType
}

interface FileDropCsv {
  file: File
  location: string
}

interface FileDrop {
  files: FileDropFile[]
  csv: {
    domain?: FileDropCsv
    article?: FileDropCsv
    production?: FileDropCsv
  }
  errors: string[]
}

interface Result {
  domain: string
  title: string
  www?: boolean | null
  dirname?: string | null
  error?: string | null
}

interface State {
  show: Show
  disabled: Disabled
  filedrop: FileDrop
  results: Result[]
  location?: string
}

const state = reactive<State>({
  show: { validation: false, loading: true, submitLoading: false },
  disabled: { submit: true },
  filedrop: {
    files: [],
    csv: {},
    errors: [],
  },
  results: [],
})

let router: Router
const setup = () => {
  router = useRouter()

  // onMounted(async () => {
  //   // console.log(1)
  // })

  return
}

const requestFileStatus = async (fileStates: FileDropFile) => {
  const fileState = state.filedrop.files.find(
    (file) => file.file.name === fileStates.file.name,
  )

  if (!fileState) throw new Error('No such file state')

  const location = await getUploadLocation()
  const url = location.data?.satellitesiteGetUploadLocation.url
  if (!url) {
    fileState.loading = false
    throw new Error('Failed getUploadLocation')
  }

  // ファイルのアップロード
  try {
    await axios.put(url, fileState.file, {
      onUploadProgress: (e: ProgressEvent) => {
        fileState.progress = (e.loaded / e.total) * 100
      },
    })
    const location = new URL(url).pathname.split('/').pop()
    if (!location) throw new Error('Failed url parse')

    fileState.loading = true

    if (fileState.file.type === 'application/zip') {
      const ret = await addTemplate(location)
      const resultTemplates = ret.data?.satellitesiteAddTemplate
      if (resultTemplates?.templates) {
        if (resultTemplates?.templates.length > 0) {
          fileState.valid = true
        } else {
          fileState.error =
            'Zipファイルの中からテンプレートを見つけることができませんでした。'
          fileState.valid = false
        }
      } else {
        fileState.error = 'テンプレートアップロードに失敗しました。'
        fileState.valid = false
      }
    } else {
      const ret = await buildFileValidation(location)
      const type = ret.data?.satellitesiteBuildFileValidation?.type
      // console.log('type', type)

      if (type) {
        switch (type) {
          case 'domain':
            state.filedrop.csv['domain'] = { file: fileState.file, location }
            break
          case 'article':
            state.filedrop.csv['article'] = { file: fileState.file, location }
            break
          case 'production':
            state.filedrop.csv['production'] = {
              file: fileState.file,
              location,
            }
            break
        }
        fileState.valid = true
      } else {
        fileState.error = '対応していないフォーマットです。'
        fileState.valid = false
      }
    }
  } catch (err) {
    fileState.error = 'アップロードに失敗しました。'
    fileState.valid = false
    console.log(err)
  } finally {
    fileState.loading = false
  }

  if (
    state.filedrop.csv.domain &&
    state.filedrop.csv.article &&
    state.filedrop.csv.production
  ) {
    state.filedrop.files = []
    state.show.validation = true
    state.show.loading = true

    requestBuildFileSetValidation()
  }
}

const requestBuildFileSetValidation = async () => {
  if (
    !state.filedrop.csv.domain ||
    !state.filedrop.csv.article ||
    !state.filedrop.csv.production
  ) {
    throw new Error('Empty csv')
  }

  state.disabled.submit = true
  state.results = []

  const ret = await buildFileSetValidation({
    domain: state.filedrop.csv.domain.location,
    article: state.filedrop.csv.article.location,
    production: state.filedrop.csv.production.location,
  })

  const validation = ret.data?.satellitesiteBuildFileSetValidation
  if (validation?.location) {
    state.location = validation.location
    state.disabled.submit =
      validation.results.find((result) => result.error !== null) !== undefined

    state.results = validation.results.map((result) => {
      if (result.error) {
        switch (result.error) {
          case 'No such template dir':
            result.error = 'テンプレートが見つかりませんでした。'
            break
        }
      }

      return result
    })
  }

  state.show.loading = false
}

const setFiles = (files: File[]) => {
  state.filedrop.errors = []
  state.filedrop.files = []

  for (const file of files) {
    if (!/\.(csv|xls|xlsx|zip)$/.test(file.name)) {
      state.filedrop.errors.push(
        `「${file.name} 」は対応していないファイル形式です。`,
      )
      continue
    }

    const fileStatus: FileDropFile = {
      file,
      filesize: prettyBytes(file.size),
      error: '',
      loading: false,
      valid: false,
      progress: 0,
    }

    state.filedrop.files.push(fileStatus)

    requestFileStatus(fileStatus)
  }
}

const filedrop: FileSystemEntriesCallback = async (entries) => {
  const files = await getFilesFromEntries(entries)
  setFiles(files)
}

const onChangeFile = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (target.files) setFiles(Array.from(target.files))
}

const buildSite = async () => {
  if (!state.location) throw new Error('Empty location')
  state.show.submitLoading = true
  state.disabled.submit = true

  try {
    const ret = await buildSiteExecution(state.location)
    const id = ret.data?.satellitesiteBuildSiteExecution.id
    if (id) {
      router.push(`/satellite-site/batch/build/site/${id}`)
    }
  } catch (err) {
    console.log(err)
  } finally {
    state.show.submitLoading = false
    state.disabled.submit = false
  }
}

interface Store {
  state: State
  setup: () => void
  filedrop: FileSystemEntriesCallback
  onChangeFile: (e: Event) => void
  buildSite: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  filedrop,
  onChangeFile,
  buildSite,
}

export default store
