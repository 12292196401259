import { describeRegisterDomainExecution } from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { InjectionKey, onMounted, reactive } from 'vue'
import { Router, useRouter } from 'vue-router'

interface Show {
  loading: boolean
  result: boolean
}

interface Disabled {
  reload: boolean
}

interface DomainResult {
  domain: string
  progress: boolean
  succeed: boolean
  message?: string
  error?: string | null
}

interface Result {
  total: number
  succeed: number
  progress: number
  errors: number
  completed: boolean
  domains: DomainResult[]
}

interface State {
  id: string
  location: string
  show: Show
  disabled: Disabled
  result: Result
}

const state = reactive<State>({
  id: '',
  location: '',
  show: {
    loading: false,
    result: false,
  },
  disabled: {
    reload: false,
  },
  result: {
    total: 0,
    succeed: 0,
    progress: 0,
    errors: 0,
    completed: false,
    domains: [],
  },
})

let router: Router
let reloadTimer: NodeJS.Timer
const reloadInterval = 30 * 1000

const setup = () => {
  router = useRouter()

  onMounted(async () => {
    await requestDescribeExecution()
  })
}

const requestDescribeExecution = async () => {
  if (reloadTimer) {
    clearInterval(reloadTimer)
  }

  if (state.disabled.reload) return

  state.disabled.reload = true
  const id = router.currentRoute.value.params.id as string
  if (state.id !== id) {
    state.show.result = false
    state.show.loading = true
  }

  state.id = id

  const result = await describeRegisterDomainExecution(id)
  const describe = result.data?.satellitesiteDescribeRegisterDomainExecution
  if (!describe) {
    state.show.loading = false
    state.disabled.reload = false
    return
  }

  state.result.domains = []
  for (const result of describe) {
    const succeed = result.results.find((r) => r.error !== null) === undefined
    const progress =
      result.results.find((r) => r.type === 'cloudfront') === undefined
    const findError = result.results.find((r) => r.error !== null)
    const hasNameServer =
      result.results.find((r) => r.nameServers !== null) !== undefined

    const message =
      hasNameServer && progress
        ? 'ネームサーバーの変更を待っています。未設定ドメインを確認してください。'
        : undefined

    let error: string | undefined | null = undefined
    if (findError) {
      switch (findError.error) {
        case 'Too many request':
          error = `処理が混み合っているため、ネームサーバーの変更に時間がかかっています。`
          break
        default:
          error = findError.error
          break
      }
    }

    state.result.domains.push({
      domain: result.domain,
      error,
      message,
      progress,
      succeed: succeed && !progress,
    })
  }

  state.result.total = describe.length
  state.result.succeed = state.result.domains.filter((d) => d.succeed).length
  state.result.progress = state.result.domains.filter((d) => d.progress).length
  state.result.errors = state.result.domains.filter((d) => d.error).length

  state.show.result = true
  state.show.loading = false
  state.disabled.reload = false

  if (state.result.progress > 0) {
    reloadTimer = setInterval(() => {
      reload()
    }, reloadInterval)
  }
}

const reload = async () => {
  await requestDescribeExecution()
}

interface Store {
  state: State
  setup: () => void
  reload: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  reload,
}

export default store
