<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col class="text-subtitle-1 pr-0 font-weight-bold">
          既存サイトの移行
        </v-col>
      </v-row>
    </v-container>

    <v-list>
      <v-list-item
        to="/satellite-site/import/upload"
        prepend-icon="mdi-numeric-1-circle-outline"
      >
        DNS移行前ドメインリストのアップロード
      </v-list-item>
      <v-list-item
        to="/satellite-site/import/upload2"
        prepend-icon="mdi-numeric-2-circle-outline"
      >
        移行後ドメインのサイト生成リストのアップロード
      </v-list-item>

      <v-divider />

      <v-list-item
        to="/satellite-site/batch/import/domain/"
        prepend-icon="mdi-history"
      >
        ドメインインポートの実行履歴
      </v-list-item>

      <v-list-item
        to="/satellite-site/batch/import/site/"
        prepend-icon="mdi-history"
      >
        サイトインポートの実行履歴
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteImportConsole',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    //
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.usersTable {
  overflow: scroll;
  // header - footer - actions
  height: calc(100vh - 48px - 32px - 60px);
  padding: 0;
}
</style>
