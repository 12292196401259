
import { defineComponent, inject } from 'vue'

import registerStore from '@/store/satellite-site/domain/register'

import FileDrop from '@/components/parts/FileDrop.vue'
import Table from '@/components/parts/Table.vue'

export default defineComponent({
  name: 'SatelliteSiteDomainRegister',
  components: {
    FileDrop,
    Table,
  },
  setup() {
    const store = inject(registerStore.key, registerStore)
    if (!store) {
      throw new Error('Empty store')
    }

    const resultTableKey = store.setup()

    return { store, resultTableKey }
  },
})
