
import { defineComponent, inject } from 'vue'
import createStore from '@/store/satellite-site/create'
import FileDrop from '@/components/parts/FileDrop.vue'

export default defineComponent({
  name: 'SatelliteSiteCreate',
  components: {
    FileDrop,
  },
  setup() {
    const store = inject(createStore.key, createStore)
    if (!store) throw new Error('Empty store')

    store.setup()

    return {
      store,
    }
  },
})
