<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="12" class="text-subtitle-1 pr-0 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">
            <router-link to="/satellite-site/batch/import/domain"
              >ドメインインポートバッチ</router-link
            >
          </span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">詳細 {{ store.state.id }}</span>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-circular
      v-if="store.state.show.loading"
      indeterminate
      class="mx-3"
    />

    <v-container v-if="store.state.show.result" class="flex-row pt-0" fluid>
      <v-card class="pb-4" tile>
        <v-card-item class="pa-4 pb-2">
          <v-row>
            <v-col>
              <b>バッチ処理内容</b>
              <span class="text-subtitle-2 rounded bg-info pa-1 px-2 mx-2"
                >ドメイン数 {{ store.state.result.total }}</span
              >
              <span class="text-subtitle-2 rounded bg-success pa-1 px-2"
                >完了 {{ store.state.result.succeed }}</span
              >
            </v-col>
            <v-col class="text-right">
              <v-btn @click="store.excuteBatch" color="primary" flat
                >サイト生成バッチを実行する</v-btn
              >
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-item>
          <v-row v-for="domain in store.state.result.domains" :key="domain">
            <v-col>
              <div class="px-2">
                <v-icon
                  :icon="domain.succeed ? 'mdi-check' : 'mdi-close'"
                  :color="domain.succeed ? 'success' : 'error'"
                />
                {{ domain.domain }}
              </div>
            </v-col>
          </v-row>
        </v-card-item>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

import detailStore from '@/store/satellite-site/batch/import/domain/detail'

// import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteBatchImportDomainDetail',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    const store = inject(detailStore.key, detailStore)
    if (!store) {
      throw new Error('Empty store')
    }

    store.setup()

    return { store }
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.chevron {
  opacity: var(--v-disabled-opacity);
}
.usersTable {
  overflow: scroll;
  // header - footer - actions
  height: calc(100vh - 48px - 32px - 60px);
  padding: 0;
}
</style>
