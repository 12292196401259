
import { defineComponent } from 'vue'
import store from '@/store/satellite-site/update'
import FileDrop from '@/components/parts/FileDrop.vue'
import Overlay from '@/components/parts/Overlay.vue'

export default defineComponent({
  name: 'SatelliteSiteUpdate',
  components: {
    FileDrop,
    Overlay,
  },
  setup() {
    store.setup()

    return {
      store,
    }
  },
})
