<template>
  <NavigationDrawer title="サテライトサイト">
    <v-list-item to="/satellite-site/create" class="px-2">
      <v-icon icon="mdi-folder-upload-outline" class="mr-1" />
      サイト作成
    </v-list-item>

    <v-list-item to="/satellite-site/update" class="px-2">
      <v-icon icon="mdi-folder-edit-outline" class="mr-1" />
      サイト更新
    </v-list-item>

    <v-list-item to="/satellite-site/move" class="px-2">
      <v-icon icon="mdi-folder-move-outline" class="mr-1" />
      サイト移転
    </v-list-item>

    <v-list-item to="/satellite-site/delete" class="px-2">
      <v-icon icon="mdi-folder-remove-outline" class="mr-1" />
      サイト削除
    </v-list-item>

    <v-list-item to="/satellite-site/domain/pending" class="px-2">
      <v-icon icon="mdi-progress-wrench" class="mr-1" />
      未設定ドメイン
    </v-list-item>

    <v-divider class="my-2" />

    <v-list-item to="/satellite-site/import" class="px-2">
      <v-icon icon="mdi-import" class="mr-1" />
      既存サイトの移行
    </v-list-item>
  </NavigationDrawer>

  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavigationDrawer from '@/components/parts/NavigationDrawer.vue'

export default defineComponent({
  name: 'SatelliteSite',
  components: {
    NavigationDrawer,
  },
})
</script>
