
import { defineComponent, inject } from 'vue'

import detailStore from '@/store/satellite-site/batch/import/site/detail'

// import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteBatchDetail',
  components: {
    // Table,
    // FlashBanner,
  },
  setup() {
    const store = inject(detailStore.key, detailStore)
    if (!store) {
      throw new Error('Empty store')
    }

    store.setup()

    return { store }
  },
})
