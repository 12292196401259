import { describeBuildSiteExecution } from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { InjectionKey, onMounted, reactive } from 'vue'
import { Router, useRouter } from 'vue-router'

interface Show {
  loading: boolean
  result: boolean
}

interface DomainResult {
  domain: string
  succeed: boolean
  error?: string | null
}

interface Result {
  location: string
  status?: string
  statusClass?: string
  total: number
  succeed: number
  errors: number
  domains: DomainResult[]
}

type Filter = 'all' | 'completed' | 'error'

interface State {
  id: string
  location: string
  show: Show
  filter: Filter
  result: Result
}

const state = reactive<State>({
  id: '',
  location: '',
  show: {
    loading: false,
    result: false,
  },
  filter: 'all',
  result: {
    location: '',
    total: 0,
    succeed: 0,
    errors: 0,
    domains: [],
  },
})

let router: Router
let reloadTimer: NodeJS.Timer
const reloadInterval = 10 * 1000

const getStatus = (status?: string | null) => {
  switch (status) {
    case 'RUNNING':
      return '実行中'
    case 'SUCCEEDED':
      return '成功'
    case 'FAILED':
      return '失敗'
    case 'TIMED_OUT':
      return 'タイムアウト'
    case 'ABORTED':
      return '中断'
    default:
      return undefined
  }
}

const getStatusClass = (status?: string | null) => {
  switch (status) {
    case 'RUNNING':
      return 'text-primary'
    case 'SUCCEEDED':
      return 'text-success'
    case 'FAILED':
      return 'text-error'
    case 'TIMED_OUT':
      return 'text-error'
    case 'ABORTED':
      return 'text-secondary'
    default:
      return undefined
  }
}

const setup = () => {
  router = useRouter()

  onMounted(async () => {
    state.result = {
      location: '',
      total: 0,
      succeed: 0,
      errors: 0,
      domains: [],
    }

    await requestDescribeExecution()
  })
}

const requestDescribeExecution = async () => {
  if (reloadTimer) {
    clearInterval(reloadTimer)
  }

  state.show.loading = true
  const id = router.currentRoute.value.params.id as string
  if (state.id !== id) {
    state.show.result = false
  }

  state.id = id

  const result = await describeBuildSiteExecution(id)
  const describe = result.data?.satellitesiteDescribeBuildSiteExecution
  // console.log(describe)

  if (!describe || !describe.location || !describe.domains) {
    state.show.loading = false
    return
  }

  const domains = describe.domains.map((d) => {
    const error = d?.error
    return {
      domain: d.domain,
      succeed: error === null,
      error,
    }
  })

  const total = describe.domains.length
  const succeed = domains.reduce((p, d) => (p += d.succeed ? 1 : 0), 0)
  const errors = domains.reduce((p, d) => (p += d.error ? 1 : 0), 0)

  state.result = {
    location: describe.location,
    status: getStatus(describe.status),
    statusClass: getStatusClass(describe.status),
    total,
    succeed,
    errors,
    domains,
  }

  state.show.result = true
  state.show.loading = false

  if (total == 0 || total - succeed - errors > 0) {
    reloadTimer = setInterval(() => {
      reload()
    }, reloadInterval)
  }
}

const reload = async () => {
  await requestDescribeExecution()
}

const clickFilterAll = async () => {
  state.filter = 'all'
}

const clickFilterCompleted = async () => {
  state.filter = 'completed'
}

const clickFilterError = async () => {
  state.filter = 'error'
}

interface Store {
  state: State
  setup: () => void
  reload: () => void
  clickFilterAll: () => void
  clickFilterCompleted: () => void
  clickFilterError: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  reload,
  clickFilterAll,
  clickFilterCompleted,
  clickFilterError,
}

export default store
