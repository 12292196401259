<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col cols="7" class="text-subtitle-1 font-weight-bold">
          <span class="d-inline-flex">サテライトサイト</span>
          <v-icon
            icon="mdi-chevron-right"
            size="24"
            :class="$style.chevron"
            class="mb-1"
            disabled
          />
          <span class="d-inline-flex">サイト更新</span>
        </v-col>
        <v-col class="text-right">
          <v-btn to="/satellite-site/batch/update/site">
            <v-icon icon="mdi-history" class="mr-1" />サイト更新の実行履歴
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-if="store.state.show.search"
      class="flex-row pt-0"
      :class="$style.search"
      fluid
    >
      <v-row no-gutters>
        <v-col class="d-flex align-center">
          <div :class="$style.searchInputArea">
            <input
              id="template-editor-search-input"
              :class="$style.searchInput"
              v-model="store.state.search.input"
              @keyup.stop.prevent="store.onKeyupInput"
              @change.stop.prevent="store.onChangeInput"
              type="text"
              autofocus
              autocomplete="on"
              list="search-domain-suggests"
              placeholder="ドメイン名を入力してください"
            />
            <v-progress-circular
              v-if="store.state.show.searchLoading"
              :class="$style.searchLoading"
              indeterminate
              size="20"
            />
            <datalist id="search-domain-suggests">
              <option
                v-for="domain in store.state.search.suggests"
                :key="domain"
                :value="domain"
              />
            </datalist>
          </div>

          <div class="text-subtitle-2 ml-4">テンプレート</div>

          <v-btn
            @click="store.showUploadTemplateArea"
            class="mx-2"
            :disabled="store.state.disabled.templateUploadButton"
          >
            <v-icon icon="mdi-upload" class="mr-1" /> アップロード
          </v-btn>
          <v-btn
            @click="store.clickDownloadTemplate"
            :disabled="
              store.state.disabled.templateDownloadButton ||
              store.state.show.downloadLoading
            "
          >
            <v-progress-circular
              v-if="store.state.show.downloadLoading"
              class="mr-1"
              size="21"
              indeterminate
            />
            <v-icon v-else icon="mdi-download" class="mr-1" />
            ダウンロード</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-progress-circular
      v-if="store.state.show.templateLoading"
      indeterminate
      class="mx-3 mb-4"
    />

    <v-container
      v-if="store.state.result.errors.length > 0"
      class="flex-row pt-0"
      fluid
    >
      <v-list>
        <v-list-item
          class="px-0"
          v-for="error in store.state.result.errors"
          :key="error"
        >
          {{ error }}
        </v-list-item>
      </v-list>
    </v-container>

    <v-container
      v-if="
        !store.state.show.templateLoading &&
        store.state.editor.resource.origins.length > 0
      "
      :class="$style.editor"
      class="flex-row pt-0"
      fluid
    >
      <v-card tile>
        <v-row no-gutters>
          <v-col cols="2" :class="$style.fileColumn">
            <div :class="$style.resourceColumn">ファイル</div>
            <select
              :class="$style.resources"
              :size="store.state.editor.resource.origins.length"
              v-model="store.state.editor.selectResource"
              @change="store.changeSelectResource"
              @keydown="store.onKeypressSelectContent"
            >
              <option
                v-for="res in store.state.editor.resource.origins"
                :class="[
                  $style.resourcesItem,
                  res.delete
                    ? 'bg-error text-decoration-line-through'
                    : res.dirty
                    ? 'bg-success'
                    : '',
                ]"
                :key="res"
                :value="res.file"
                :selected="store.state.editor.selectResource === res.file"
              >
                {{ res.file }}
              </option>
            </select>
          </v-col>
          <v-col class="d-flex flex-column">
            <v-row :class="$style.resourceColumn" no-gutters>
              <v-col class="d-flex justify-space-between">
                <div>
                  {{ store.state.editor.selectResource }}
                </div>

                <v-btn
                  v-if="store.state.editor.deletable"
                  @click="store.clickDeleteFile"
                  size="small"
                  elevation="0"
                  style="margin: -2px 0 -3px"
                  variant="outlined"
                >
                  <div
                    v-if="store.state.editor.delete"
                    class="d-flex align-center"
                  >
                    <v-icon icon="mdi-undo-variant" size="14" />
                    <div class="d-flex">元に戻す</div>
                  </div>
                  <div v-else class="d-flex align-center">
                    <v-icon icon="mdi-close" size="14" />
                    <div class="d-flex">更新時にこのファイルを削除する</div>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-if="store.state.editor.location.length > 0"
              class="d-flex"
              :class="$style.resourceImageRow"
            >
              <v-row class="align-start" no-gutters>
                <v-col
                  :style="{
                    backgroundImage: `url(${store.state.editor.location})`,
                  }"
                  :class="$style.resourceImage"
                >
                </v-col>
                <v-col class="align-self-start">
                  <FileDrop
                    @filedrop="store.filedropImage"
                    :class="$style.resourceImageDrop"
                  >
                    画像を変更する場合は、ここに画像ファイルをドラッグアンドドロップするか、<v-btn
                      variant="outlined"
                      size="small"
                      class="d-inline-block mx-1 pa-0"
                    >
                      <label class="pa-2">
                        ファイルを選択
                        <input
                          @change="store.onChangeImageFile"
                          class="d-none"
                          type="file"
                          name="upload"
                        /> </label></v-btn
                    >をしてください。

                    <v-progress-circular
                      v-if="store.state.filedropImage.loading"
                      :class="$style.resourceImageLoading"
                      indeterminate
                    />

                    <div
                      v-if="store.state.editor.dataUrl.length > 0"
                      class="d-flex align-end justify-end pa-1"
                      :class="$style.resourceUpdateImage"
                      :style="{
                        backgroundImage: `url(${store.state.editor.dataUrl})`,
                      }"
                    >
                      {{ store.state.filedropImage.filesize }}
                      <v-btn
                        @click="store.clickCancelUploadImage"
                        class="ml-1"
                        color="error"
                        title="画像変更をやめる"
                        elevation="0"
                        size="small"
                        ><v-icon icon="mdi-trash-can" size="20"
                      /></v-btn>
                    </div>
                  </FileDrop>
                </v-col>
              </v-row>
            </div>

            <textarea
              v-else
              :class="$style.resourceContent"
              @change="store.changeSelectContent"
              @keyup="store.onKeyupSelectContent"
              v-model="store.state.editor.content"
              :disabled="store.state.editor.delete"
              spellcheck="false"
            />
            <v-card-actions class="justify-end">
              <v-btn
                color="primary"
                class="px-4"
                @click="store.clickUpdateSiteFiles"
                :disabled="store.state.disabled.updateButton"
              >
                <v-progress-circular
                  v-if="store.state.show.uploadLoading"
                  class="mr-1"
                  size="21"
                  indeterminate
                />
                <v-icon v-else icon="mdi-cloud-upload-outline" class="mr-1" />
                サイトを更新する</v-btn
              >
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <Overlay v-if="store.state.show.uploadTemplateArea">
      <v-card :class="$style.uploadTemplateArea">
        <v-card-title
          >テンプレートをアップロードしてサイトを更新する</v-card-title
        >
        <v-card-item>
          <FileDrop
            @filedrop="store.filedropTemplate"
            :class="$style.templateDropArea"
            all
          >
            <p>
              ここにアップロードするテンプレートファイル（Zipファイル）をドラッグアンドドロップするか、<v-btn
                variant="outlined"
                size="small"
                class="d-inline-block mx-1 pa-0"
              >
                <label class="pa-2">
                  ファイルを選択
                  <input
                    @change="store.onChangeImageFile"
                    class="d-none"
                    type="file"
                    name="upload"
                  /> </label></v-btn
              >をしてください。
            </p>

            <p class="mt-4">
              1サイト分のテンプレートファイルを
              <b class="text-error">フォルダ毎 Zipファイル</b>
              で圧縮してアップロードしてください。
            </p>

            <v-progress-circular
              v-if="store.state.filedropTemplate.loading"
              :class="$style.resourceImageLoading"
              indeterminate
            />

            <v-list
              v-if="store.state.filedropTemplate.errors.length > 0"
              class="pb-0"
            >
              <v-list-item
                v-for="error in store.state.filedropTemplate.errors"
                :key="error"
                class="bg-error"
              >
                {{ error }}
              </v-list-item>
            </v-list>
          </FileDrop>
        </v-card-item>
        <v-card-actions>
          <v-btn class="ml-auto mr-2" @click="store.hideUploadTemplateArea">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="store.clickUpdateSiteTemplate"
            :disabled="store.state.disabled.templateUploadSubmitButton"
            class="px-4"
          >
            <v-progress-circular
              v-if="store.state.show.templateUploadLoading"
              class="mr-1"
              size="21"
              indeterminate
            />
            <v-icon v-else icon="mdi-cloud-upload-outline" class="mr-1" />
            サイトを更新する
          </v-btn>
        </v-card-actions>
      </v-card>
    </Overlay>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import store from '@/store/satellite-site/update'
import FileDrop from '@/components/parts/FileDrop.vue'
import Overlay from '@/components/parts/Overlay.vue'

export default defineComponent({
  name: 'SatelliteSiteUpdate',
  components: {
    FileDrop,
    Overlay,
  },
  setup() {
    store.setup()

    return {
      store,
    }
  },
})
</script>


<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.search {
  min-width: 750px !important;
}
.searchInputArea {
  position: relative;
}
.searchInput {
  min-width: 300px;
  padding: 5px 8px 5px 8px;
  border-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: rgba(var(--v-border-color), var(--v-medium-emphasis-opacity));
  background: rgb(var(--v-theme-background));
  &:focus {
    outline: none;
  }
}
.searchButton {
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-width: thin;
  border-style: solid;
  border-color: rgba(var(--v-border-color), var(--v-medium-emphasis-opacity));
}
.searchLoading {
  position: absolute;
  opacity: 0.01;
  right: 8px;
  top: 7px;
}
.editor {
  min-width: 750px !important;
}
.fileColumn {
  border-right: solid thin rgba(var(--v-border-color), var(--v-border-opacity));
}
.resources {
  background: rgb(var(--v-theme-background));
  margin: 8px 0;
  padding: 0 8px;
  width: 100%;
  height: calc(100vh - 48px - 32px - 181px);
  &:focus {
    outline: none;
  }
}
.resourcesItem {
  padding: 4px 8px;
  margin-bottom: 1px;
  overflow: hidden;
}
.resourceContent {
  background: rgba(var(--v-theme-on-background), var(--v-hover-opacity));
  width: calc(100% - 16px);
  // header - footer
  height: calc(100vh - 48px - 32px - 222px);
  min-height: 300px;
  border: ridge 2px rgba(var(--v-theme-on-background), var(--v-hover-opacity));
  border-top: ridge 2px
    rgba(var(--v-theme-on-background), var(--v-disabled-opacity));
  border-left: ridge 2px
    rgba(var(--v-theme-on-background), var(--v-disabled-opacity));
  resize: none;
  padding: 8px;
  margin: 8px;
  margin-bottom: 0;
  overflow: auto !important;

  &:focus {
    outline: none;
  }
}
.resourceImageRow {
  height: calc(100vh - 48px - 32px - 214px);
}
.resourceImage {
  margin: 8px 8px 0;
  height: calc(100vh - 48px - 32px - 224px);
  background-color: rgba(var(--v-border-color), var(--v-hover-opacity));
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.resourceImageLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -8px;
}
.resourceUpdateImage {
  margin: 8px 0 0;
  height: calc(100vh - 48px - 32px - 320px);
  background-color: rgba(var(--v-border-color), var(--v-hover-opacity));
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
}
.resourceImageDrop {
  position: relative;
  height: calc(100vh - 48px - 32px - 224px);
  margin: 7px 8px 0 0 !important;
}
.resourceColumn {
  padding: 8px;
  font-weight: bold;
  border-bottom: solid thin rgba(var(--v-border-color), var(--v-border-opacity));
}
.uploadTemplateArea {
  width: 100vw;
  max-width: 600px;
}
.templateDropArea {
  margin: 0 8px !important;
  min-height: 130px;
}
</style>
