import { GraphQLMutationResult, GraphQLQueryResult } from '@/graphql'
import API, { graphqlOperation } from '@aws-amplify/api'
import gql from 'graphql-tag'
import { Status } from '.'

export const buildFileValidation = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($location: String!) {
          satellitesiteBuildFileValidation(location: $location) {
            type
          }
        }
      `,
      { location },
    ),
  ) as GraphQLQueryResult<'satellitesiteBuildFileValidation'>

export const buildFileSetValidation = (input: {
  domain: string
  article: string
  production: string
}) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($domain: String!, $article: String!, $production: String!) {
          satellitesiteBuildFileSetValidation(
            input: {
              domain: $domain
              article: $article
              production: $production
            }
          ) {
            location
            results {
              domain
              www
              title
              dirname
              error
            }
          }
        }
      `,
      { ...input },
    ),
  ) as GraphQLQueryResult<'satellitesiteBuildFileSetValidation'>

export const addTemplate = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteAddTemplate(location: $location) {
            templates
          }
        }
      `,
      { location },
    ),
  ) as GraphQLMutationResult<'satellitesiteAddTemplate'>

export const buildSiteExecution = (location: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        mutation ($location: String!) {
          satellitesiteBuildSiteExecution(location: $location) {
            id
          }
        }
      `,
      { location },
    ),
  ) as GraphQLMutationResult<'satellitesiteBuildSiteExecution'>

export const listBuildSiteExecutions = (status?: Status, nextToken?: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($status: String, $nextToken: String) {
          satellitesiteListBuildSiteExecutions(
            status: $status
            nextToken: $nextToken
          ) {
            executions {
              name
              status
              startDate
              stopDate
            }
            nextToken
          }
        }
      `,
      { status, nextToken },
    ),
  ) as GraphQLQueryResult<'satellitesiteListBuildSiteExecutions'>

export const describeBuildSiteExecution = (name: string) =>
  API.graphql(
    graphqlOperation(
      gql`
        query ($name: String!) {
          satellitesiteDescribeBuildSiteExecution(name: $name) {
            domains {
              domain
              error
            }
            location
            status
          }
        }
      `,
      {
        name,
      },
    ),
  ) as GraphQLQueryResult<'satellitesiteDescribeBuildSiteExecution'>
