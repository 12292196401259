import {
  describeImportDomainExecution,
  importSiteExecution,
} from '@/graphql/satellite-site'
import { BaseStore } from '@/store'
import { InjectionKey, onMounted, reactive } from 'vue'
import { Router, useRouter } from 'vue-router'

interface Show {
  loading: boolean
  result: boolean
}

interface DomainResult {
  domain: string
  succeed: boolean
}

interface Result {
  location: string
  total: number
  succeed: number
  domains: DomainResult[]
  errors: string[]
}

interface State {
  id: string
  location: string
  show: Show
  result: Result
}

const state = reactive<State>({
  id: '',
  location: '',
  show: {
    loading: false,
    result: false,
  },
  result: {
    location: '',
    total: 0,
    succeed: 0,
    domains: [],
    errors: [],
  },
})

let router: Router
let reloadTimer: NodeJS.Timer
const reloadInterval = 30 * 1000

const setup = () => {
  router = useRouter()

  onMounted(async () => {
    await requestDescribeExecution()
  })
}

const requestDescribeExecution = async () => {
  if (reloadTimer) {
    clearInterval(reloadTimer)
  }

  state.show.loading = true
  const id = router.currentRoute.value.params.id as string
  if (state.id !== id) {
    state.show.result = false
  }

  state.id = id

  const result = await describeImportDomainExecution(id)
  const describe = result.data?.satellitesiteDescribeImportDomainExecution
  console.log(describe)

  if (
    !describe ||
    !describe.location ||
    !describe.domains ||
    !describe.errors
  ) {
    state.show.loading = false
    return
  }

  const total = describe.domains.length
  const errorCount = describe.errors.length
  const succeed = describe.domains.reduce((p, d) => (p += d.succeed ? 1 : 0), 0)

  state.result = {
    location: describe.location,
    total,
    succeed,
    domains: describe.domains,
    errors: describe.errors,
  }

  state.show.result = true
  state.show.loading = false

  if (total - succeed - errorCount > 0) {
    reloadTimer = setInterval(() => {
      reload()
    }, reloadInterval)
  }
}

const reload = async () => {
  await requestDescribeExecution()
}

const excuteBatch = async () => {
  try {
    const result = await importSiteExecution(state.result.location)
    const id = result.data?.satellitesiteImportSiteExecution.id
    if (id) {
      // バッチ詳細画面に遷移する
      router.push(`/satellite-site/batch/import/site/${id}`)
    }
  } catch (e) {
    console.log(e)
  }
}

interface Store {
  state: State
  setup: () => void
  reload: () => void
  excuteBatch: () => void
}

type S = Readonly<Store>
const key: InjectionKey<S> = Symbol()
const store: BaseStore<S> = {
  key,
  state,
  setup,
  reload,
  excuteBatch,
}

export default store
