<template>
  <v-container class="d-flex flex-column" :class="$style.container" fluid>
    <v-container class="flex-row" fluid>
      <v-row>
        <v-col class="text-subtitle-1 pr-0 font-weight-bold">
          移行後ドメインのサイト生成リストのアップロード
        </v-col>
      </v-row>
    </v-container>

    <v-container class="flex-row pt-0" fluid>
      <FileDrop @filedrop="store.filedrop" all>
        ここにアップロードするファイルをドラッグアンドドロップするか、<v-btn
          variant="outlined"
          size="small"
          class="d-inline-block mx-1 pa-0"
          ><label class="pa-2">
            ファイルを選択
            <input
              @change="store.onChangeFile"
              class="d-none"
              type="file"
              name="upload" /></label></v-btn
        >をしてください。

        <v-card v-if="store.state.filedrop.file" class="mt-4" tile flat>
          <v-card-item class="d-flex align-center">
            <v-progress-circular
              class="d-flex mr-1"
              size="20"
              v-if="
                store.state.filedrop.progress < 100 ||
                store.state.filedrop.loading
              "
              :model-value="store.state.filedrop.progress"
              :indeterminate="store.state.filedrop.loading"
            />
            <v-icon
              v-else
              class="d-flex mr-1"
              size="20"
              :icon="
                store.state.filedrop.valid
                  ? 'mdi-check-circle-outline'
                  : 'mdi-close-circle-outline'
              "
              :color="store.state.filedrop.valid ? 'success' : 'error'"
            />
            <div class="d-flex">
              {{ store.state.filedrop.file.name }}
              （{{ store.state.filedrop.filesize }}）
            </div>
          </v-card-item>
        </v-card>

        <v-card
          v-if="store.state.filedrop.errors.length"
          class="mt-4"
          color="error"
          tile
          flat
        >
          <v-card-item
            v-for="error in store.state.filedrop.errors"
            :key="error"
          >
            <v-card-title>
              <v-icon icon="mdi-close-circle-outline" class="mr-1" />
              {{ error }}
            </v-card-title>
          </v-card-item>
        </v-card>
      </FileDrop>

      <v-card v-if="store.state.show.info" border tile flat>
        <v-card-title>
          移行対象のサイトリストをアップロードしてください
        </v-card-title>
        <v-card-item class="on-surface pa-4">
          <p class="mb-4">
            サイトリストは、Excelまたは、CSV形式で下記のフォーマットにしたがってください。
          </p>

          <ul class="ml-6 mb-4">
            <li>1カラム目にサテライトサイトURLを指定してください。</li>
            <li>
              2カラム目にSCRSのアカウントを指定してください。（S + 数字の指定）
            </li>
          </ul>

          <p class="my-4">例</p>

          <table border="1" cellspacing="0">
            <tr>
              <th class="pa-2 bg-secondary">サイトURL</th>
              <th class="pa-2 bg-secondary">SCRSサーバー</th>
            </tr>
            <tr>
              <td class="pa-2">https://stellite-site.com</td>
              <td class="pa-2">S123</td>
            </tr>
            <tr>
              <td class="pa-2">https://www.stellite-site.net</td>
              <td class="pa-2">S456</td>
            </tr>
          </table>
        </v-card-item>
      </v-card>

      <v-card
        v-if="store.state.show.result"
        :class="$style.result"
        :loading="store.state.result.loading"
        border
        tile
        flat
      >
        <v-card-item v-if="store.state.result.errors.length">
          <v-card-title
            v-for="error in store.state.result.errors"
            :key="error"
            class="text-error"
          >
            <v-icon
              icon="mdi-close-circle-outline"
              color="error"
              class="mr-1"
            />
            {{ error }}
          </v-card-title>
        </v-card-item>
        <v-card-actions v-else>
          <div class="pl-2">
            ドメインは<span class="pa-2 text-success font-weight-black"
              >{{ store.state.result.total }}件</span
            >あります。
          </div>
          <v-btn
            @click="store.clickImportSiteExecution"
            :disabled="store.state.disabled.execution"
            color="primary"
            class="ml-auto px-6"
            >サイト生成を実行する</v-btn
          >
        </v-card-actions>
        <v-card-item :class="$style.resultTable">
          <Table :table-key="resultTableKey" />
        </v-card-item>
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

import importStore from '@/store/satellite-site/import'

import FileDrop from '@/components/parts/FileDrop.vue'
import Table from '@/components/parts/Table.vue'
// import FlashBanner from '@/components/parts/FlashBanner.vue'
// import listStore from '@/store/admin/users/list'

export default defineComponent({
  name: 'SatelliteSiteImportIndex',
  components: {
    FileDrop,
    Table,
  },
  setup() {
    const store = inject(importStore.key, importStore)
    if (!store) {
      throw new Error('Empty store')
    }

    const resultTableKey = store.setup()

    return { store, resultTableKey }
  },
})
</script>

<style module lang="scss">
.container {
  background-color: rgb(var(--v-theme-secondary));
  // header - footer
  height: calc(100vh - 48px - 32px);
  padding: 0;
}
.dropArea {
  border-style: dashed;
}
.result {
  // header - footer - page caption - file drop area - bottom margin
  height: calc(100vh - 48px - 32px - 52px - 125px - 15px);
}
.resultTable {
  // header - footer - page caption - file drop area - bottom margin - card title
  height: calc(100vh - 48px - 32px - 52px - 125px - 15px - 57px);
  overflow: scroll;
}
</style>
